import { environment } from "src/environments/environment";

/***** Breadcrum Methods *****/
export class Globals {
    goBackToAllApplication() {
        window.location.href = environment.all_application;
    }
    goBackToLob(lobName: string) {
        window.location.href = `${environment.all_application_lob}${lobName?.toLowerCase()}`;
    }
    goBackToUserManagement() {
        window.location.href = environment.user_management;
    }

    /**
 * Fuction used for AutoFocs
 * @param
 * form: form name
 * el: element name
 */
    autoFocusOnError = (form, el, isModal?) => {
        form.markAllAsTouched();
        for (const key of Object.keys(form.controls)) {
            if (form.controls[key].invalid) {
                let rootElement = isModal ? document.body : el.nativeElement;
                let invalidControl =
                    rootElement.querySelector('[formcontrolname="' + key + '"]') ||
                    rootElement.querySelector('[name="' + key + '"]');
                if (invalidControl == null) continue;
                if (invalidControl?.tagName == "NG-SELECT") {
                    invalidControl = invalidControl.querySelector(
                        "div.ng-select-container input"
                    );
                } else if (invalidControl?.tagName == "NG-MULTISELECT-DROPDOWN") {
                    invalidControl = invalidControl.querySelector(
                        "div.multiselect-dropdown"
                    );
                }
                invalidControl.focus();
                break;
            }
        }
    };

    isNumberKey(evt: any) {
        if (
            (evt.key >= '0' && evt.key <= '9') ||
            evt.key == 'Backspace' ||
            evt.key == 'Delete' ||
            evt.key == 'ArrowLeft' ||
            evt.key == 'ArrowRight'
        ) {
            return true;
        }
        return false;
    }
    leapYear(year){
        return ((year % 4 == 0) && (year % 100 != 0)) || (year % 400 == 0);
    }

    encodeString(input) {
        const startChars = 'AA';
        const endChars = 'ZZ';
        const modifiedString = startChars + input + endChars;
        const encodedString = btoa(modifiedString);
        return encodedString;
    }
}