import {
  ChangeDetectorRef,
  Component,
  OnInit,
  TemplateRef,
} from "@angular/core";
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { BaseServiceService } from "../services/base-service.service";
import { SessionStorageService } from "../services/session-storage.service";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { COMMON_WORDS } from "../services/constants";
import Swal from "sweetalert2";

@Component({
  selector: "app-additional-discount-details",
  templateUrl: "./additional-discount-details.component.html",
  styleUrls: ["./additional-discount-details.component.scss"],
})
export class AdditionalDiscountDetailsComponent implements OnInit {
  referToArray = [];
  actionRequired = [];
  recommendForApproval: any[] = [];
  takeActionForm: FormGroup;
  isSubmitted = false;
  modalRef: BsModalRef;
  isNSMFlow = false;
  showRecommendForApproval = false;
  baseQuotePremium = 0;
  requestedDiscount = 0;
  producerName = "";
  quoteId = "";
  cToken = "";
  config: NgbModalOptions = {
    backdrop: "static",
    keyboard: false,
    size: "sm",
  };
  viewHistoryModalRef: BsModalRef;
  noHistoryFound = false;
  discountingData = [];
  discountingHistoryContent: any[] = [];
  type = "";
  producerCode = "";
  locationCode = "";
  channelType = "";
  employeeCode = "";
  userType = "";
  role = "";
  ntId = "";
  assignedByEmail = "";
  createdByEmail = "";
  level = "";
  documentId: string | null = null;
  uploadedDocuments: any[] = [];
  showLoader = true;
  isDocumentIdFetched = false;
  showButtonLoader = false;
  assigneeEmail = "";
  assigneeNumber = "";
  assigneeName = "";
  channelId = "";
  lobValue = "";
  redirectUrl = "";
  completion_url = "";
  validatedAssignee = "";
  showTakeActionForm = false;
  isUploadSuccessful: boolean[] = [];
  isStatusQueried = false;
  createdByName = "";
  remarkControl: FormControl = new FormControl({ value: '', disabled: true });
  successModalRef: BsModalRef;

  constructor(
    private formBuilder: FormBuilder,
    private modalService: BsModalService,
    private toastr: ToastrService,
    private baseService: BaseServiceService,
    private sessionStorageService: SessionStorageService,
    private route: ActivatedRoute,
    private router: Router,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    const fullUrl = window.location.href;
    const encodedStr = fullUrl.split("?")[1];
    if (!encodedStr.includes("ctoken")) {
      this.decodeUrl(encodedStr);
    } else {
      this.getQueryParams();
    }
    this.config["class"] = "theme-modal modal-sm";
  }

  decodeUrl(encodedStr: string) {
    const decodedStr = decodeURIComponent(encodedStr);
    const params = new URLSearchParams(decodedStr);
    this.quoteId = params.get("quoteid");
    this.redirectUrl = params.get("redirect_url");
    this.completion_url = params.get("completion_url");
    this.handleAllFunctions();
  }

  getQueryParams() {
    this.route.queryParams.subscribe((params: Params): void => {
      this.quoteId = params.quoteid;
      this.cToken = params.ctoken;
    });
    if (this.cToken) {
      this.handleCtoken(this.cToken);
      this.isNSMFlow = true;
    }
  }

  handleAllFunctions() {
    this.showTakeActionForm = true;
    this.initializeForm();
    if (!this.cToken) {
      this.getProfile();
    }
    this.getItemsFromSessionStorage();
    this.getPolicyDocumentId(this.quoteId);
    this.fetchDiscountRequest();
    this.fetchDiscountHistory(this.quoteId);
    if (!this.cToken) {
      this.getReferToDetails();
    }
    if (this.isNSMFlow) {
      this.fetchActionInfo();
    }
  }

  getProfile() {
    this.baseService.profile().subscribe({
      next: (response: any) => {
        if (response.status === 0) {
          const { role, channel, nt_login, email, first_name } = response.data.data;
          this.role = role;
          this.channelType = channel;
          this.ntId = nt_login ? nt_login : "";
          this.assignedByEmail = email;
          this.createdByName = first_name;
        }
      },
      error: (error: any) => {
        this.toastr.error(error);
      },
    });
  }

  handleCtoken(cToken: string) {
    this.baseService.getProfileData(cToken).subscribe({
      next: (res: any) => {
        let { channel, broker_code, email, employee_id } = res.data.data;
        if (res.status == 0) {
          let channelName = channel ?? "";
          let producerCode = broker_code ?? "";
          let Email = email ?? "";
          let employeeCode = employee_id ?? "";
          this.sessionStorageService.setItem("broker_code", producerCode);
          this.sessionStorageService.setItem("producer_code", producerCode);
          this.sessionStorageService.setItem("channel", channelName);
          this.sessionStorageService.setItem("email", Email);
          this.sessionStorageService.setItem(COMMON_WORDS.usertype, "employee");
          this.sessionStorageService.setItem("employee_id", employeeCode);
        }
        const body = { ctoken: cToken };
        this.baseService.ctokenNsm(body).subscribe(async (res) => {
          const {
            status,
            data: {
              quote_id,
              ntid,
              channel_id,
              location_code,
              currentAssignee,
              level,
            },
          } = res;
          if (status == 0) {
            this.quoteId = quote_id;
            this.ntId = ntid;
            this.channelId = channel_id;
            this.locationCode = location_code;
            this.assignedByEmail = currentAssignee;
            this.level = level;
            this.validateLink(this.assignedByEmail);
          }
        });
      },
      error: (err: any) => {
        this.toastr.error(err);
      },
    });
  }

  validateLink(assignedByEmail: string) {
    this.showLoader = true;
    this.baseService.validateLink(this.quoteId).subscribe(
      (res) => {
        if (res.status === 0) {
          this.showLoader = false;
          this.validatedAssignee = res.data[0].assigned_to;
          this.checkAssignee(assignedByEmail);
        }
      },
      (error) => {
        this.toastr.error("Error validating current assignee", error);
      }
    );
  }

  checkAssignee(assignedByEmail: string) {
    if (this.validatedAssignee === assignedByEmail) {
      this.handleAllFunctions();
    } else {
      Swal.fire("Oops...", "Quote is not assigned to you.", "error");
      this.showTakeActionForm = false;
    }
  }

  restrictInput(event: KeyboardEvent): void {
    const regex = /^[0-9.]$/;
    if (!regex.test(event.key) && event.key !== "Backspace") {
      event.preventDefault();
    }
  }

  premiumValidator(basePremium: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const updatedPremium = control.value;
      return updatedPremium > basePremium ? { premiumExceeded: true } : null;
    };
  }

  discountValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const discount = control.value;
      if (discount < 0) {
        return { negativeDiscount: true };
      }
      if (discount > 100) {
        return { discountExceeded: true };
      }
      return null;
    };
  }

  initializeForm() {
    const initialUpdatedPremium =
      this.baseQuotePremium -
      this.baseQuotePremium * (this.requestedDiscount / 100);
    this.takeActionForm = this.formBuilder.group({
      referTo: [""],
      remark: ["", Validators.required],
      updatedPremium: [
        initialUpdatedPremium.toFixed(2),
        [this.premiumValidator(this.baseQuotePremium)],
      ],
      updatedDiscount: [
        this.requestedDiscount.toFixed(2),
        [this.discountValidator()],
      ],
      actionRequired: [""],
      recommendForApproval: [""],
      docUpload: this.formBuilder.group({
        document: [""],
        name: [""],
        type: [""],
      }),
    });

    this.takeActionForm
      .get("updatedPremium")
      .valueChanges.subscribe((value) => {
        const basePremium = this.baseQuotePremium;
        const discountedPrice = basePremium - value;
        const calculatedDiscount = (discountedPrice / basePremium) * 100;
        this.takeActionForm.patchValue(
          {
            updatedDiscount: calculatedDiscount.toFixed(2),
          },
          { emitEvent: false }
        );
      });

    this.takeActionForm
      .get("updatedDiscount")
      .valueChanges.subscribe((value) => {
        const basePremium = this.baseQuotePremium;
        const discountPercentage = value;
        const discountedPrice = basePremium * (1 - discountPercentage / 100);
        this.takeActionForm.patchValue(
          {
            updatedPremium: discountedPrice.toFixed(2),
          },
          { emitEvent: false }
        );
      });
  }

  getItemsFromSessionStorage(): void {
    this.channelType = this.sessionStorageService?.getItem("channel");
    this.producerCode = this.sessionStorageService?.getItem("producer_code");
    this.locationCode =
      this.locationCode || this.sessionStorageService?.getItem("location_code");
    this.employeeCode = this.sessionStorageService.getItem("employee_id");
    this.userType = this.sessionStorageService.getItem("usertype");
    const fetchChannel = JSON.parse(
      this.sessionStorageService?.getItem("fetch_channel")
    );
    this.channelId =
      this.channelId || fetchChannel?.data?.data[0]?.num_channel_id;
    this.lobValue = this.sessionStorageService.getItem("lob_value") ?? "";
  }

  getPolicyDocumentId(quoteId: string): void {
    this.isDocumentIdFetched = false;
    this.baseService.policy(quoteId).subscribe(
      (response: any) => {
        if (response.status === 0) {
          this.isDocumentIdFetched = true;
          this.documentId = response.data[0].document_id;
          this.createdByEmail = response.data[0].created_by;
        }
      },
      (error) => {
        this.toastr.error("Error fetching policy details:", error);
      }
    );
  }

  fetchDiscountRequest(): void {
    this.showLoader = true;
    this.baseService.fetchDiscountRequest(this.quoteId).subscribe({
      next: (response: any) => {
        this.showLoader = false;
        this.discountingData = response.data.map((resp: any) => {
          this.baseQuotePremium = resp.base_quote_premium;
          this.requestedDiscount = resp.required_discount_precentage;
          this.producerName = resp.producer_name;
          this.quoteId = resp.quote_id;
          this.lobValue = resp.lob;
          this.initializeForm();
          return {
            quote_id: resp.quote_id,
            producer_name: resp.producer_name,
            mapped_tagic_location: resp.mapped_tagic_location,
            product_name: resp.product_name,
            client_name: resp.client_name,
            policy_period: resp.policy_period,
            base_quote_premium: resp.base_quote_premium,
            no_of_lives: resp.no_of_lives,
            required_premium: resp.required_premium,
            required_discount_precentage: resp.required_discount_precentage,
            remarks: resp.remarks,
            discount_status: resp.discount_status,
          };
        });
      },
    });
  }

  async fetchActionInfo(): Promise<void> {
    const initiatorType = this.level;
    this.baseService.fetchActionInfo(initiatorType).subscribe(async (res) => {
      if (res?.data) {
        this.actionRequired = [];
        res.data.forEach((item: any) => {
          Object.keys(item).forEach((key) => {
            if (item[key] === "Y") {
              switch (key) {
                case "approve":
                  this.actionRequired.push("Approve");
                  break;
                case "reject":
                  this.actionRequired.push("Reject");
                  break;
                case "send_to_next_approver":
                  this.actionRequired.push("Send To Next Approver");
                  break;
                case "ask_additional_info_producer":
                  this.actionRequired.push("Ask Additional Info Producer");
                  break;
                case "ask_info_rm":
                  this.actionRequired.push("Ask Info RM");
                  break;
                default:
                  break;
              }
            }
          });
        });
      }
    });
  }

  toggleApprovalFieldVisibility(event: any): void {
    if (event === "Send To Next Approver") {
      this.showRecommendForApproval = true;
      this.gethigherReportee();
    } else {
      this.showRecommendForApproval = false;
    }
  }

  showViewHistoryModal(view_history_template: TemplateRef<any>) {
    this.viewHistoryModalRef = this.modalService.show(
      view_history_template,
      this.config
    );
    this.fetchDiscountHistory(this.quoteId);
  }

  fetchDiscountHistory(quoteId: string): void {
    this.baseService.fetchHistoryLogs(quoteId).subscribe(
      (response) => {
        if (response.status === 0) {
          if (response.data && response.data.length > 0) {
            let previousName = '';
            this.discountingHistoryContent = response.data.map((item: any) => {
              const currentName = (item.name || previousName).toUpperCase();
              if (item.name) {
                previousName = item.name;
              }
              if (item.status === 'queried') {
                this.isStatusQueried = true;
              }
              return {
                name: currentName,
                date: item.c_ts,
                remarks: item.Remarks,
                status: item.status,
                queriedBy: item.assigned_by,
                doc_attached: item.doc_attached,
                doc_name: item.doc_name,
              };
            });
            const latestEntry = this.discountingHistoryContent[this.discountingHistoryContent.length - 1];
            const latestRemarks = latestEntry ? latestEntry.remarks : '';
            this.remarkControl.setValue(latestRemarks);
            this.remarkControl.disable();
            this.noHistoryFound = false;
          } else {
            this.discountingHistoryContent = [];
            this.noHistoryFound = true;
          }
        } else {
          this.noHistoryFound = true;
        }
      },
      (error) => {
        if (error.status === -103) {
          this.noHistoryFound = true;
        } else {
          this.toastr.error("Error fetching discount history:", error);
        }
      }
    );
  }

  onAcceptOrResend(option: string): void {
    const referTo = this.takeActionForm.get("referTo").value;
    if (option === "accept") {
      this.toastr.success("Quote accepted by you");
    } else {
      this.toastr.success(`Quote resent to ${referTo} for Discount Approval`);
    }
  }

  getStatusClasses(status: string): string {
    const discountStatus = status?.toLowerCase();
    if (discountStatus === "rejected") {
      return "status-declined";
    } else if (discountStatus === "approved") {
      return "status-approved";
    } else if (discountStatus === "queried") {
      return "status-queried";
    } else if (discountStatus === "assigned") {
      return "status-assigned";
    } else if (discountStatus === "answered") {
      return "status-approved";
    } else {
      return "";
    }
  }

  getCurrentTime(): string {
    const now = new Date();
    const year = now.getFullYear();
    const month = ("0" + (now.getMonth() + 1)).slice(-2);
    const day = ("0" + now.getDate()).slice(-2);
    const hours = ("0" + now.getHours()).slice(-2);
    const minutes = ("0" + now.getMinutes()).slice(-2);
    const seconds = ("0" + now.getSeconds()).slice(-2);

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  onFileSelected(event: any, formCtrlName: string): void {
    const file = event.target.files[0];
    if (file) {
      this.setFormFileName(file, formCtrlName);
      this.cd.markForCheck();
    }
  }

  setFormFileName(file: File, formCtrlName: string): void {
    const formCtrl = this.takeActionForm.get(formCtrlName) as FormGroup;
    formCtrl.patchValue({
      name: file.name,
      document: file,
      type: file.type,
    });
    this.uploadedDocuments.push(formCtrl.value);
  }

  async documentUpload(formCtrlName: string, index: number) {
    const formCtrl = this.takeActionForm.get(formCtrlName) as FormGroup;
    if (formCtrl.valid) {
      await this.createFormAndSubmitDoc(this.uploadedDocuments[index], index);
    }
  }

  async createFormAndSubmitDoc(param: any, index: number): Promise<void> {
    this.isUploadSuccessful[index] = false;
    let formData = new FormData();
    formData.append("file", param.document);
    this.baseService
      .documentAttach(formData, {
        document_id: this.documentId,
        name: param.name,
        document_type: param.type,
      })
      .subscribe((res: any) => {
        if (res) {
          const { status, txt } = res.body;
          if (status === -102) {
            this.toastr.error(txt);
          } else if (status === -106) {
            this.toastr.error(txt);
          } else {
            this.toastr.success("Document Upload Successful");
            this.isUploadSuccessful[index] = true;
          }
        }
      });
  }

  removeDocument(index: number) {
    this.uploadedDocuments.splice(index, 1);
    this.isUploadSuccessful.splice(index, 1);
    this.takeActionForm.get("docUpload").get("name").setValue("");
    this.toastr.success("Document Removed Successfully");
  }

  getReferToDetails() {
    let params = {};
    if (this.role === "Producer") {
      this.userType = "producer";
      params = {
        type: this.userType,
        producerCode: this.producerCode,
        branchCode: this.locationCode,
        channelType: this.channelType?.toLowerCase(),
      };
    } else {
      params = {
        type: this.userType,
        employeeNTId: this.ntId,
      };
    }
    this.baseService.referToDetails(params).subscribe((response) => {
      const { success, data } = response.data;
      if (success) {
        this.referToArray = [
          {
            name: data.name.toUpperCase(),
            code: data.code,
            email: data.email,
            phoneNumber: data.phoneNumber,
            level: data.level,
          },
        ];
      }
    });
  }

  gethigherReportee() {
    const params = {
      requestedDiscount: this.requestedDiscount,
      channelCode: this.channelId,
      lobValue: this.lobValue,
      locationCode: this.locationCode,
      smeOrNonSme: "sme",
      approverEmailId: this.assignedByEmail,
    };
    this.baseService.higherReportee(params).subscribe((response) => {
      const { success, data } = response.data;
      if (success) {
        this.recommendForApproval = [
          {
            name: data.Name,
            code: data.code,
            email: data.Email,
            level: data.level,
            phoneNumber: data.MobileNo,
          },
        ];
      }
    });
  }

  callNsmMailServices(nsmMail: any, success_template: any): void {
    this.baseService.nsmMailService(nsmMail).subscribe(
      (res) => {
        const { status, data } = res;
        if (status === 0) {
          if (data?.sendMailResponse) {
            this.toastr.success(data?.sendMailResponse?.message);
            this.successModalRef = this.modalService.show(
              success_template,
              this.config
            );
          }
        }
      },
      (error) => {
        this.toastr.error(error);
      }
    );
  }

  onApprovalChange(event: any) {
    this.assigneeEmail = event.email;
    this.assigneeNumber = event.phoneNumber;
    this.assigneeName = event.name;
    this.level = event.level;
  }

  routeback() {
    if (!this.cToken) {
      window.location.href = this.redirectUrl;
    }
  }

  routeToReviewQuote() {
    if (!this.cToken) {
      window.location.href = this.completion_url;
    }
  }

  getActionValue(action: string): string {
    if (action == "Approve") {
      return "approved";
    } else if (action == "Reject") {
      return "rejected";
    } else if (action == "Ask Additional Info Producer") {
      return "queried";
    } else if (action == "Send To Next Approver") {
      return "assigned";
    } else if (action == "Ask Info RM") {
      return "ask additional info";
    } else if (this.isStatusQueried) {
      return "answered";
    }
  }

  getAssignedToEmail(actionRequired: string): string {
    if (
      ["Approve", "Reject", "Ask Additional Info Producer"].includes(
        actionRequired
      )
    ) {
      return this.createdByEmail;
    } 
    else {
      return this.assigneeEmail;
    }
  }

  async downloadQuote() {
    await this.baseService.downloadQuote(this.quoteId)
  }

  getDocumentUrl(doc: string) {
   
  }

  onSubmit(success_template: any): void {
    this.isSubmitted = false;
    this.showButtonLoader = true;
    if (this.uploadedDocuments.length > 0) {
      const areAllDocumentsUploaded = this.uploadedDocuments.every((_, index) => this.isUploadSuccessful[index]);
      if (!areAllDocumentsUploaded) {
        this.toastr.error("Documents are not uploaded. Please upload the documents before submitting.");
        this.showButtonLoader = false;
        return; 
      }
    }
    const referTo = this.takeActionForm.get("referTo").value;
    const remarks = this.takeActionForm.get("remark").value;
    const actionRequired = this.takeActionForm.get("actionRequired").value;
    const successfulDocNames = this.uploadedDocuments
    .filter((_, index) => this.isUploadSuccessful[index])  
    .map(doc => doc.name); 
  const isDocAttached = successfulDocNames.length > 0 ? "Yes" : "No";
  const docNames = successfulDocNames;
    const currentTime = this.getCurrentTime();
    const finalizedDiscountPrecentage =
      +this.takeActionForm.get("updatedDiscount").value;
    const updatedPremium = this.takeActionForm.get("updatedPremium").value;

    let discountDataEmail = this.getAssignedToEmail(actionRequired);
    if (this.isStatusQueried) {
      const lastQueriedPerson = this.discountingHistoryContent
        .filter(item => item.status === 'queried')
        .slice(-1)[0];
      if (lastQueriedPerson) {
        discountDataEmail = lastQueriedPerson.queriedBy;
      }
    }

    let currentStatus;
    if (this.role.toLowerCase() === "producer" && this.isStatusQueried){
      currentStatus = "answered";
    } else if (this.role.toLowerCase() === "producer" && !(this.isStatusQueried)){
      currentStatus = "assigned";
    } else {
      currentStatus = this.getActionValue(actionRequired);
    }

    let assignedPerson;
    if (currentStatus == "answered"){
      assignedPerson = this.createdByName;
    } else {
      assignedPerson = this.assigneeName;
    }

    const discountData = {
      email: discountDataEmail,
      name: assignedPerson,
      assigned_by: this.assignedByEmail,
      status: currentStatus,
      required_discount_precentage: this.requestedDiscount,
      finalized_discount_precentage: finalizedDiscountPrecentage,
      updatedPremium: updatedPremium,
      level: this.level,
      doc_attached: isDocAttached,
      doc_name: docNames,
      Remarks: remarks,
      c_ts: currentTime,
    };
    let emailRecipient = this.assigneeEmail ? this.assigneeEmail : this.createdByEmail;
    if (this.isStatusQueried) {
      const lastQueriedPerson = this.discountingHistoryContent
        .filter(item => item.status === 'queried')
        .slice(-1)[0]; 
      if (lastQueriedPerson) {
        emailRecipient = lastQueriedPerson.queriedBy;
      }
    }
    const nsmMail = {
      quoteId: this.quoteId,
      module:
        this.role.toLowerCase() === "producer"
          ? "assigned"
          : this.getActionValue(actionRequired),
      name: this.assigneeName,
      mobileNo: this.assigneeNumber,
      email: emailRecipient,
    };
    this.baseService.statusHistory(this.quoteId, discountData).subscribe({
      next: (res: any) => {
        if (res.status === 0) {
          this.isSubmitted = true;
          this.showButtonLoader = false;
          const {
            data: { status, assigned, txt },
          } = res;
          if (status === -104) {
            this.toastr.error(txt);
          } else {
            if (this.isNSMFlow) {
              this.toastr.success(`Quote ${actionRequired}`);
            } else {
              this.toastr.success(status, assigned);
            }
          }
          // if (this.isNSMFlow) {
          //   if (this.showRecommendForApproval) {
          //     this.callNsmMailServices(nsmMail, success_template);
          //   }
          // }
          //  else 
           {
            this.callNsmMailServices(nsmMail, success_template);
          }
        }
      },
      error: (err: any) => {
        this.toastr.error(err);
        this.showButtonLoader = false;
      },
    });
    this.fetchDiscountHistory(this.quoteId);
  }
}
