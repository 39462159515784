<div
  class="theme-container quote-listing-page search"
  (click)="closeDropMenu()"
>
  <!-- Page header start -->
  <div class="page-title">
    <div class="nav-btn" (click)="back()">
      <svg>
        <use xlink:href="#ICON_Arrow" />
      </svg>
    </div>
    <div class="w-100">
      <div class="page-heading-container">
        <h3 class="head-lg heading" #target>
          {{ "searchComponent.search.label" | translate }}
        </h3>
      </div>
      <div class="breadcrum-container" *ngIf="lobValue">
        <ul class="breadcrum">
          <!-- <li class="head-sm" (click)="globals.goBackToAllApplication()">
            {{ "commonText.applicationBreadcrumb" | translate }}
          </li> -->
          <li *ngIf="route.snapshot?.queryParams?.type !== 'crossSell'" class="head-sm" (click)="globals.goBackToAllApplication()">
            {{ lobValue }}
          </li>
          <li class="head-sm">
            {{ "searchComponent.search.label" | translate }}
          </li>
        </ul>
      </div>
      <div class="breadcrum-container" *ngIf="!lobValue">
        <ul class="breadcrum">
          <li class="head-sm" (click)="globals.goBackToAllApplication()">
            {{ "commonText.applicationBreadcrumb" | translate }}
          </li>
          <li class="head-sm">
            {{ "searchComponent.search.label" | translate }}
          </li>
        </ul>
      </div>
    </div>
  </div>
  <!-- Page header end -->

  <div class="row">

    <div class="col-12 mb-10 d-flex justify-content-end">
      <button class="btn primary-btn active " (click)="navigateToNewSearch()">
        New Search
      </button>
    </div>

    <div class="col-12">
      <form
        class="theme-form mt-mobile"
        (ngSubmit)="search(0, true)"
        name="form"
        #f="ngForm"
      >
        <div
          class="theme-card w-100"
          [ngClass]="{ 'brdr-bottom': showFilterDiv }"
        >
          <div class="card-content p-8">
            <div class="row">
              <div
                class="form-field col-md-6 col-lg-4 col-xl-3"
                *ngIf="showLobs"
              >
                <label for="model" class="head-sm required-field">{{
                  "searchComponent.selectLob.label" | translate
                }}</label>
                <ng-select
                  id="model"
                  name="lob"
                  placeholder=" {{
                    'commonText.select.placeholder' | translate
                  }}"
                  (change)="toggleFilterDiv(); onLobClick($event)"
                  [(ngModel)]="slctdLob"
                  #lob="ngModel"
                  required
                  [readonly]="this.lobData.length == 1"
                >
                  <ng-option
                    *ngFor="let data of lobData; let i = index"
                    [value]="data.lob_value"
                  >
                    {{ data.lob }}</ng-option
                  >
                </ng-select>
                <div
                  class="input-loader dropdown-input-loader"
                  *ngIf="selectLobLoader"
                >
                  <div class="fa-2x">
                    <i class="fa fa-spinner fa-spin"></i>
                  </div>
                </div>
                <span class="error-msg" *ngIf="!lob.valid && lob.touched">{{
                  "searchComponent.lob.required" | translate
                }}</span>
              </div>
              <div class="col-md-6 col-lg-4 col-xl-3" *ngIf="displaydropdown">
                <div class="h-100 d-flex align-items-center">
                  <div class="check-default">
                    <input
                      type="checkbox"
                      class="checkbox"
                      id="cb"
                      [checked]="receiptSearchBtn"
                      (change)="onReceiptCheckChange()"
                    />
                    <label for="cb">
                      {{ "searchComponent.receiptUtilised.label" | translate }}
                    </label>
                  </div>
                </div>
              </div>
              <div
                class="form-field col-md-6 col-xl-3"
                [ngClass]="showLobs ? 'col-lg-4' : 'col-lg-2'"
                *ngIf="displaydropdown"
              >
                <label for="model" class="head-sm">{{
                  "searchComponent.selectStage.label" | translate
                }}</label>
                <ng-select
                  id="selectStage"
                  [disabled] = "route.snapshot?.queryParams?.type === 'crossSell'"
                  placeholder=" {{
                    'commonText.select.placeholder' | translate
                  }}"
                  (change)="selectedStage($event)"
                  name="selectStage"
                  [(ngModel)]="selectedStageVal"
                  [readonly]="receiptSearchBtn"
                >
                  <ng-option
                    *ngFor="let jj of selectedStageList; let i = index"
                    [value]="jj.value"
                  >
                    {{ jj.value }}</ng-option
                  >
                </ng-select>
              </div>
              <div
                class="form-field col-md-6 col-lg-4 col-xl-3"
                *ngIf="selectedStageVal == 'Certificate'"
              >
                <label for="model" class="head-sm">{{
                  "searchComponent.masterPolicy" | translate
                }}</label>
                <ng-select
                  [(ngModel)]="selectedCertificatePolicy"
                  name="master_policy"
                  #master_policy="ngModel"
                  placeholder="{{
                    'commonText.select.placeholder' | translate
                  }}"
                  (change)="onChangeCertificatePolicy()"
                  required
                >
                  <ng-option
                    *ngFor="let jj of certificatePolicyList; let i = index"
                    value="{{ jj }}"
                  >
                    {{ jj }}</ng-option
                  >
                </ng-select>
                <span
                  class="error-msg"
                  *ngIf="!master_policy.valid && master_policy.touched"
                  >{{
                    "searchComponent.masterPolicy.required" | translate
                  }}</span
                >
              </div>
              <div
                class="form-field col-md-6 col-xl-3"
                [ngClass]="showLobs ? 'col-lg-4': 'col'"
                *ngIf="conditionlRequired && displaydropdown"
              >
                <label for="" class="head-sm">{{
                  "searchComponent.selectDuration.label" | translate
                }}</label>
                <span class="info-icon premium-tooltip premium-info-icon">
                  <span class="tooltiptext">
                    {{ "searchComponent.note" | translate }}</span
                  >
                  <svg class="svg-xs">
                    <use xlink:href="#ICON_Info" />
                  </svg>
                </span>
                <div class="row">
                  <div class="col-md-6 pr-md-0">
                    <input
                      type="text"
                      class="form-control ngb-cursor-pointer default-ngb-datePicker"
                      placeholder="{{
                        'searchComponent.date.placeholder' | translate
                      }}"
                      name="start"
                      [maxDate]="currentDate"
                      readonly
                      ngbDatepicker
                      #d="ngbDatepicker"
                      (click)="d.toggle()"
                      [(ngModel)]="model.ad_startDate"
                      #start="ngModel"
                      autocomplete="off"
                      (dateSelect)="onStartDateSelect()"
                      [ngClass]="{
                        validate: submit === true && start.valid === false
                      }"
                      [required]="conditionlRequired"
                      />
                      <!-- [disabled]="receiptSearchBtn" -->
                  </div>
                  <div class="col-md-6 pl-md-0 gy-3 gy-md-0">
                    <input
                      type="text"
                      class="form-control ngb-cursor-pointer default-ngb-datePicker"
                      placeholder="{{
                        'searchComponent.date.placeholder' | translate
                      }}"
                      [minDate]="model.ad_startDate"
                      [maxDate]="endMaxDate"
                      name="endDate"
                      readonly
                      ngbDatepicker
                      #e="ngbDatepicker"
                      (click)="e.toggle()"
                      [(ngModel)]="model.ad_endDate"
                      [required]="conditionlRequired"
                      #endDate="ngModel"
                      autocomplete="off"
                      />
                      <!-- [disabled]="receiptSearchBtn" -->
                  </div>
                </div>
              </div>
              <div class="form-field col-lg-6 col-xl-4" *ngIf="displaydropdown">
                <label for="" class="head-sm">
                  {{ "searchComponent.searchBy.label" | translate }}
                </label>
                <div class="input-group d-sm-flex">
                  <ng-select
                    id="selectedType"
                    class="col-12 mb-20 d-sm-none mw-100"
                    placeholder=" {{
                      'commonText.select.placeholder' | translate
                    }}"
                    (change)="selectedVal($event)"
                    name="selectedType"
                    [(ngModel)]="selectedType"
                    [readonly]="receiptSearchBtn"
                  >
                    <ng-option
                      *ngFor="let kk of selectedValList; let i = index"
                      [value]="kk.value"
                    >
                      {{ kk.value }}</ng-option
                    >
                  </ng-select>

                  <div
                    class="input-group-prepend rupee-refer-btn d-none d-sm-flex"
                  >
                    <button
                      class="form-control dropdown-toggle"
                      id="button-basic2"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      [disabled]="receiptSearchBtn"
                    >
                      {{ selectedType }}
                    </button>
                    <ul class="dropdown-menu dropdown-scroll">
                      <li *ngFor="let kk of selectedValList">
                        <a
                          class="dropdown-item"
                          [attr.id]="kk.id"
                          (click)="selectedVal(kk.value)"
                          >{{ kk.value }}</a
                        >
                      </li>
                    </ul>
                  </div>
                  <input
                    type="text"
                    [(ngModel)]="model.searchby"
                    #searchby="ngModel"
                    name="searchby"
                    maxlength="50"
                    class="form-control rupee-referal-input"
                    [disabled]="receiptSearchBtn"
                    *ngIf="!premiumdisplay"
                    (focusout)="validateSpecial()"
                  />
                  <input
                    type="text"
                    [(ngModel)]="model.min"
                    #min="ngModel"
                    name="min"
                    maxlength="20"
                    class="form-control rupee-referal-input"
                    [max]="model.max"
                    placeholder="{{
                      'searchComponent.min.placeholder' | translate
                    }}"
                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')"
                    [disabled]="receiptSearchBtn"
                    *ngIf="premiumdisplay"
                  />
                  <input
                    type="text"
                    [(ngModel)]="model.max"
                    #max="ngModel"
                    name="max"
                    maxlength="20"
                    class="form-control rupee-referal-input"
                    placeholder="{{
                      'searchComponent.max.placeholder' | translate
                    }}"
                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')"
                    [disabled]="receiptSearchBtn"
                    *ngIf="premiumdisplay"
                  />
                </div>
              </div>
              <div
                *ngIf="displaydropdown"
                class="col-lg-2 align-items-center d-flex gy-3 gy-md-0"
              >
                <button
                  type="button"
                  [disabled]="!clickbutton || searchSpinner"
                  type="submit"
                  class="btn primary-btn theme-lg-btn sm mr-0"
                >
                  {{ "searchComponent.search.label" | translate }}
                </button>
              </div>
              <div class="theme-value" *ngIf="submit">Note: <span class="theme-label">Maximum 700 records can be fetched</span></div>
            </div>
          </div>
        </div>

        <!-- Filter section -->
        <div class="brdr-bottom py-4 mb-5" *ngIf="showFilterDiv">
          <div class="cover">
            <div class="pull-left">
              <button
                class="btn quotelist-btn lefty"
                type="button"
                (click)="scrollDiv($event, 'left')"
              >
                <svg class="svg-xs">
                  <use xlink:href="#ICON_left_arrow" />
                </svg>
              </button>
            </div>
            <div class="scroll background-color pb-2">
              <div class="filter-text" *ngIf="sortOrder">{{ sortOrder }}
                <button type="button"(click)="removeFilter(sortOrder)" class="btn-close" aria-label="Close"></button>
              </div>
              <div *ngIf="productCategory?.length" class="d-flex">
                <div *ngFor="let item of displayproductName">
                  <div class="filter-text">
                    {{ item }}
                    <button type="button" (click)="removeFilter(item)" class="btn-close" aria-label="Close"></button>
                  </div>
                </div>
              </div>
              <div class="filter-text" *ngIf="memberisEmployee">
                {{ memberisEmployee }}
                <button type="button" (click)="removeFilter(memberisEmployee)" class="btn-close" aria-label="Close"></button>
              </div>
              <div class="filter-text" *ngIf="planType">{{ planType }}
                <button type="button" (click)="removeFilter(planType)" class="btn-close" aria-label="Close"></button>
              </div>
              <div class="filter-text" *ngIf="tenure">{{ tenure }}
                <button type="button" (click)="removeFilter(tenure)" class="btn-close" aria-label="Close"></button>
              </div>
              <div class="filter-text" *ngIf="varient">{{ varient }}
                <button type="button" (click)="removeFilter(varient)" class="btn-close" aria-label="Close"></button>
              </div>
              <div *ngIf="businessType?.length>0" class="d-flex">
                <div class="filter-text" *ngFor="let item of businessType">{{ item.value }}
                  <button type="button" (click)="removeFilter(item?.value)" class="btn-close" aria-label="Close"></button>
                </div>
              </div>
              <div *ngIf="quotes_stat?.length > 0" class="d-flex">
                <div class="filter-text" *ngFor="let item of quotes_stat">
                  {{ item?.itemName }}
                  <button type="button" (click)="removeFilter(item?.itemName)" class="btn-close" aria-label="Close"></button> 
                </div>
              </div>
              <div class="filter-text" *ngIf="filterLocation">
                {{ filterLocation }}
                <button type="button" (click)="removeFilter(filterLocation)" class="btn-close" aria-label="Close"></button> 
              </div>
              <div class="filter-text" *ngIf="subtractionType">
                {{ subtractionType }}
                <button type="button" (click)="removeFilter(subtractionType)" class="btn-close" aria-label="Close"></button> 
              </div>
              <div class="filter-text" *ngIf="netPremium">{{ netPremium }}
                <button type="button" (click)="removeFilter(netPremium)" class="btn-close" aria-label="Close"></button> 
              </div>
              <div *ngIf="gcChannel?.length > 0" class="d-flex">
                <div class="filter-text" *ngFor="let item of gcChannel">
                  {{ item.channel_type }}
                  <button type="button" (click)="removeFilter(item.channel_type)" class="btn-close" aria-label="Close"></button>
                </div>
              </div>
              <div *ngIf="gcLocation?.length > 0" class="d-flex">
                <div class="filter-text" *ngFor="let item of gcLocation">
                  {{ item.name }}
                  <button type="button" (click)="removeFilter(item.name)" class="btn-close" aria-label="Close"></button>
                </div>
              </div>
              <div class="filter-text" *ngIf="sumInsuredFrom">
                {{ sumInsuredFrom }}
                <button type="button" (click)="removeFilter(sumInsuredFrom)" class="btn-close" aria-label="Close"></button>
              </div>
              <div class="filter-text" *ngIf="sumInsuredTo">
                {{ sumInsuredTo }}
                <button type="button" (click)="removeFilter(sumInsuredTo)" class="btn-close" aria-label="Close"></button> 
              </div>
              <div class="filter-text" *ngIf="ltaBundleType">
                {{ ltaBundleType }}
                <button type="button" (click)="removeFilter(ltaBundleType)" class="btn-close" aria-label="Close"></button>  
              </div>
              <div class="filter-text" *ngIf="doeFilter?.value" >
                {{ doeFilter?.value }}
                <button type="button" (click)="removeFilter(doeFilter?.value)" class="btn-close" aria-label="Close"></button>    
              </div>
              <div class="filter-text" *ngIf="ltaPlanType">
                {{ ltaPlanType }}
                <button type="button" (click)="removeFilter(ltaPlanType)" class="btn-close" aria-label="Close"></button> 
              </div>
              <div class="filter-text" *ngIf="motorBundleType">
                {{ motorBundleType }}
                <button type="button" (click)="removeFilter(motorBundleType)" class="btn-close" aria-label="Close"></button>    
              </div>
              <div class="filter-text" *ngIf="motorvehicleType">
                {{ motorvehicleType }}
                <button type="button" (click)="removeFilter(motorvehicleType)" class="btn-close" aria-label="Close"></button>
              </div>
              <div class="filter-text" *ngIf="customerType">
                {{ customerType }}
                <button type="button" (click)="removeFilter(customerType)" class="btn-close" aria-label="Close"></button> 
              </div>
              <div class="filter-text" *ngIf="insDesFrom">{{ insDesFrom }} 
                <button type="button" (click)="removeFilter(insDesFrom)" class="btn-close" aria-label="Close"></button> 
              </div>
              <div class="filter-text" *ngIf="insDecTo">{{ insDecTo }}
                <button type="button" (click)="removeFilter(insDecTo)" class="btn-close" aria-label="Close"></button>
              </div>
              <div class="filter-text" *ngIf="btaPlanType">
                {{ btaPlanType }}
                <button type="button" (click)="removeFilter(btaPlanType)" class="btn-close" aria-label="Close"></button> 
              </div>
              <div *ngIf="producerCode?.length > 0" class="d-flex">
                <div class="filter-text" *ngFor="let item of producerCode">
                  {{ item }}
                </div>
              </div>
              <div class="filter-text" *ngIf="smeStatus">{{ smeStatus }}
                <button type="button" (click)="removeFilter(smeStatus)" class="btn-close" aria-label="Close"></button>
              </div>
              <div class="filter-text red" (click)="clear()">
                {{ "searchComponent.clearAll" | translate }}
              </div>
            </div>
            <div class="pull-right">
              <button
                class="btn quotelist-btn righty"
                type="button"
                (click)="scrollDiv($event, 'right')"
              >
                <svg class="svg-xs">
                  <use xlink:href="#ICON_right_arrow" />
                </svg>
              </button>
            </div>
          </div>
        </div>
        <!-- Filter section -->

        <div class="w-100" *ngIf="searchSpinner">
          <div class="w-100 d-flex justify-content-center head-sm">
            <div class="fa-3x">
              <i class="fa fa-spinner fa-spin"></i>
            </div>
          </div>
        </div>

        <div class="row" *ngIf="!searchSpinner">
          <div class="col-12">
            <div
              *ngIf="
                displaydropdown &&
                finalcardstatusmsg != undefined &&
                this.cardlist?.length
              "
              class="pull-left"
            >
              <form>
                <div class="form-field items-per-page-field d-flex align-items-center">
                  <label class="head-sm d-none d-md-inline-block">{{
                    "searchComponent.itemsPerPage.label" | translate
                  }}</label>
                  <ng-select
                    *ngIf="listItem"
                    class="pull-right items-per-page"
                    name="items-per-page"
                    placeholder="{{
                      'searchComponent.select.placeholder' | translate
                    }}"
                    [(ngModel)]="itemsPerPageList"
                  >
                    <ng-option value="50">50</ng-option>
                  </ng-select>
                  <ng-select
                    *ngIf="gridItem"
                    class="pull-right items-per-page"
                    name="items-per-page"
                    placeholder="{{
                      'searchComponent.select.placeholder' | translate
                    }}"
                    [(ngModel)]="itemsPerPageGrid"
                  >
                    <ng-option value="50">50</ng-option>
                  </ng-select>
                </div>
              </form>
            </div>
            <div class="head-sm mb-20 label-indent">
              <button
                *ngIf="
                  displaydropdown &&
                  finalcardstatusmsg != undefined &&
                  this.cardlist?.length
                "
                type="button"
                class="btn pull-right quotelist-btn grid-filter d-mdInvisibles grid-list-filter d-none gridbtn"
              >
                <svg
                  class="svg-size grid-list-button"
                  [ngClass]="{ 'icons-active': gridItem }"
                  (click)="grid()"
                >
                  <use xlink:href="#ICON_Grid" />
                </svg>
                <svg class="svg-size svg-three-dots">
                  <use xlink:href="#ICON_Three_Dots" />
                </svg>
                <svg
                  class="svg-size grid-list-button"
                  [ngClass]="{ 'icons-active': listItem }"
                  (click)="list()"
                >
                  <use xlink:href="#ICON_List" />
                </svg>
              </button>
              <a
                *ngIf="
                  (showFilterOption &&
                    finalcardstatusmsg != undefined &&
                    this.cardlist?.length) ||
                  (showFilterDiv &&
                    showFilterOption &&
                    finalcardstatusmsg != undefined)
                "
                class="float-end"
                (click)="sortfilter(target)"
                data-bs-toggle="offcanvas"
                data-bs-target="#filterSidebar"
                aria-controls="filterSidebar"
              >
                <button type="button" class="btn quotelist-btn icon-filter">
                  <svg class="svg-xs">
                    <use xlink:href="#ICON_Filter" />
                  </svg>
                </button>
              </a>
              <a
                *ngIf="
                  displaydropdown &&
                  finalcardstatusmsg != undefined &&
                  this.cardlist?.length
                "
                class="float-end"
              >
                <button
                  type="button"
                  [disabled]="disDownload"
                  (click)="file()"
                  class="btn quotelist-btn icon-filter"
                >
                  <svg class="svg-xs">
                    <use xlink:href="#ICON_Download" />
                  </svg>
                </button>
              </a>
            </div>
          </div>
          <div class="col-12 convert-to-proposal mt-10">
            <div
              class="grid premium-card-container"
              [ngClass]="{ 'd-md-block': gridItem, 'd-md-none': !gridItem }"
            >
              <div class="row" *ngIf="!isCrossSellFilter">
                <div
                class="col-md-6 col-lg-4 col-xl-3"
                *ngFor="let tt of cardlist; let i = index"
                (click)="navigation(tt, i, ConfirmationMessage_template, itemsPerPage)"
                >
                  <div class="tata-card-container">
                    <label class="tata-card " for="premier" [ngClass]="{ 'tata-card-banner inside-header' : addedToCartCheck(tt) }">
                      <div class="tata-card-banner-section banner-info" *ngIf="addedToCartCheck(tt)">
                        <span class="banner-text">Added in Cart </span>
                      </div>
                      <div class="tata-card-header dropdown-section">
                        <div class="align-items-center d-flex justify-content-center">
                          <div class="header-title">
                            <span class="text-capitalize"
                              >{{
                                tt.data?.search?.product_name
                                  ? tt.data?.search?.product_name
                                  : "-"
                              }}
                            </span>
                            <div class="text-capitalize" *ngIf="tt?.data?.search?.crosell_id"
                              >({{ "searchComponent.crossSell.label" | translate }})
                          </div>
                          </div>
                        </div>
                        <div>
                          <div class="dropdown">
                            <button
                              class="btn quotelist-btn m-0"
                              data-bs-toggle="dropdown"
                              (click)="
                                closeDropMenu($event);
                                onEventStopPropogation($event)
                              "
                              id="dropdownMenuButton_{{ i }}"
                              aria-expanded="false"
                            >
                              <svg class="svg-xs">
                                <use xlink:href="#ICON_Three_dots" />
                              </svg>
                            </button>
                            <ul
                              class="dropdown-menu dropdown-menu-end mt-2"
                              aria-labelledby="dropdownMenuButton1"
                            >
                              <li
                                *ngIf="
                                  tt.pdf_date &&
                                  tt.pdf_date != 'null' &&
                                  tt.showDownloadPolicyBtn
                                "
                              >
                                <a
                                  class="dropdown-item"
                                  (click)="
                                    downloadPolicy(tt); $event.stopPropagation()
                                  "
                                >
                                  <svg class="svg-xxs">
                                    <use xlink:href="#ICON_Download_Drop" />
                                  </svg>
                                  {{
                                    "searchComponent.download.label" | translate
                                  }}
                                </a>
                              </li>
                              <li>
                                <a
                                  class="dropdown-item"
                                  (click)="
                                    openShareModal(customer_email, tt);
                                    $event.stopPropagation()
                                  "
                                >
                                  <svg class="svg-xxs">
                                    <use xlink:href="#ICON_Share" />
                                  </svg>
                                  {{
                                    "searchComponent.share.label" | translate
                                  }}
                                </a>
                              </li>
                              <li *ngIf="receiptSearchBtn">
                                <a
                                  class="dropdown-item"
                                  (click)="delete(tt); $event.stopPropagation()"
                                >
                                  <svg class="svg-xxs">
                                    <use xlink:href="#ICON_delete" />
                                  </svg>
                                  {{
                                    "searchComponent.delete.label" | translate
                                  }}
                                </a>
                              </li>
                              <li
                                *ngIf="service.dAndOProductId == tt.product_id"
                              >
                                <a
                                  class="dropdown-item"
                                  (click)="navigateToProduct(tt, $event)"
                                >
                                  <svg class="svg-xxs">
                                    <use xlink:href="#ICON_clone" />
                                  </svg>
                                  <span class="pl-2">{{
                                    "searchComponent.clone.label" | translate
                                  }}</span>
                                </a>
                              </li>
                              <!-- <li><a class="dropdown-item" (click)="cloneData(tt)">{{ "searchComponent.clone.label" | translate}}</a></li> -->
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div class="tata-card-body">
                        <div class="premium-section">
                          <label
                            class="premium-label"
                            *ngIf="
                              tt.data.search != null &&
                              tt.data.search != undefined
                            "
                            >{{
                              "searchComponent.premium.label" | translate
                            }}</label
                          >
                          <span
                            class="premium-value"
                            *ngIf="
                              tt.data.search != null &&
                              tt.data.search != undefined
                            "
                            ><i class="fa fa-inr"></i
                            >{{ tt.data?.search.premium | inrcurrency }}/-</span
                          >
                          <span
                            class="premium-value"
                            *ngIf="
                              tt.data.search == null &&
                              tt.data.search == undefined
                            "
                            ><i class="fa fa-inr"></i>-</span
                          >
                        </div>
                        <div
                          class="info-section"
                          [ngClass]="{
                            'brdr-bottom':
                              (isTravel &&
                                finalcardstatus == 'policy' &&
                                tt.status == 4) ||
                              (lta &&
                                finalcardstatus == 'policy' &&
                                !tt.buttonClick) ||
                              (!mobNstpStatus &&
                                tt.data.search != null &&
                                tt.data.search != undefined &&
                                tt.data?.search?.nstp_status != '-' &&
                                (finalcardstatus == 'quote' ||
                                  finalcardstatus == 'proposal' ||
                                  finalcardstatus == 'draft' ||
                                  finalcardstatus == 'policy') &&
                                tt.data?.search?.nstp_status != null &&
                                tt.data.search.nstp_status != undefined &&
                                tt.data?.search?.nstp_status != '') ||
                              (lta &&
                                finalcardstatus == 'policy' &&
                                tt.buttonClick) ||
                              ((lob_value == 'marinecargo' ||
                                lob_value == 'aigcombined') &&
                                tt.quote?.data?.sub_transaction_type !=
                                  undefined)
                          }"
                        >
                          <ng-container
                            *ngIf="
                              tt.data.search != null &&
                              tt.data.search != undefined &&
                              tt.data.search.member_count != undefined
                            "
                          >
                            <label class="theme-value">
                              {{
                                "searchComponent.numberOfTravelers" | translate
                              }}:
                              <span class="theme-label">
                                {{ tt.data?.search.member_count }}
                              </span>
                            </label>
                          </ng-container>
                          <ng-container
                            *ngIf="
                              tt.data.search != null &&
                              tt.data.search != undefined
                            "
                          >
                            <label class="theme-value">
                              {{ "searchComponent.customerName" | translate }}
                              <span class="theme-label text-capitalize">
                                {{
                                  tt.data?.search?.customer_name
                                    ? tt.data?.search?.customer_name
                                    : "-"
                                }}
                              </span>
                            </label>
                          </ng-container>
                          <ng-container
                            *ngIf="
                              tt.data.search != null &&
                              tt.data.search != undefined &&
                              tt.data.search.selected_new_product_name !=
                                undefined
                            "
                          >
                            <label class="theme-value">
                              {{ "searchComponent.newProductName" | translate }}
                              <span
                                class="theme-label"
                                *ngIf="
                                  tt.data.search.selected_new_product_name !=
                                  undefined
                                "
                              >
                                {{ tt.data?.search.selected_new_product_name }}
                              </span>
                            </label>
                          </ng-container>
                          <ng-container
                            *ngIf="
                              tt.data.search != null &&
                              tt.data.search != undefined &&
                              tt.data.search.selected_new_product_name !=
                                undefined
                            "
                          >
                            <label class="theme-value">
                              {{ "searchComponent.coverageZone" | translate }}
                              <span
                                class="theme-label"
                                *ngIf="
                                  tt.data.search.coverage_period != undefined
                                "
                              >
                                {{ tt.data?.search.coverage_period }}
                                {{ "searchComponent.days.label" | translate }} /
                                {{ tt.data?.search.selected_zone }}
                              </span>
                            </label>
                          </ng-container>
                          <ng-container
                            *ngIf="
                              isUW &&
                              fieldshow.gc_channel &&
                              tt.data.search != null &&
                              tt.data.search != undefined &&
                              tt.data?.search.producer_name
                            "
                          >
                            <label class="theme-value">
                              {{
                                "searchComponent.producerName.label" | translate
                              }}
                              <span
                                class="theme-label"
                                *ngIf="
                                  tt.data.search != null &&
                                  tt.data.search != undefined
                                "
                              >
                                {{ tt.data?.search.producer_name }}
                              </span>
                            </label>
                          </ng-container>
                          <button
                            class="btn link-btn"
                            *ngIf="
                              lob_value == 'travel' &&
                              finalcardstatus == 'policy' &&
                              (role == 'admin' || 'cops') &&
                              privileage == true &&
                              tt.status == 0 &&
                              tt.canceled != true
                            "
                            (click)="
                              cancelcertificate(cancel_certificate);
                              policyID(tt.policy_id)
                            "
                          >
                            {{
                              "searchComponent.cancelCertificate.label"
                                | translate
                            }}
                          </button>
                          <ng-container
                            *ngIf="
                              isUW &&
                              fieldshow.gc_channel &&
                              tt.data.search != null &&
                              tt.data.search.channel != undefined &&
                              tt.data.search.channel
                            "
                          >
                            <label
                              class="theme-value"
                              *ngIf="
                                tt.data.search != null &&
                                tt.data.search.channel != undefined
                              "
                            >
                              {{ tt.data?.search.channel }}
                              <span
                                class="theme-label"
                                *ngIf="
                                  tt.data.search != null &&
                                  tt.data.search != undefined
                                "
                              >
                                {{ tt.data?.search.location }}
                              </span>
                            </label>
                          </ng-container>

                          <ng-container *ngIf="!receiptSearchBtn">
                            <ng-container
                              *ngIf="
                                ((finalcardstatus == 'quote' ||
                                  finalcardstatus == 'draft') &&
                                  tt.quote?.quote_date != undefined) ||
                                ((finalcardstatus == 'proposal' ||
                                  finalcardstatus == 'policy' ||
                                  finalcardstatus == 'certificate') &&
                                  ((tt.policy_start_date_dt != '' &&
                                    tt.policy_start_date_dt != undefined &&
                                    tt.policy_start_date_dt != 'null') ||
                                    (tt.data.search &&
                                      tt.data.search.policy_start_date != '' &&
                                      tt.data.search.policy_start_date !=
                                        undefined)))
                              "
                            >
                              <ng-container
                                *ngIf="
                                  (finalcardstatus == 'quote' ||
                                    finalcardstatus == 'draft') &&
                                  tt.quote?.quote_date != undefined
                                "
                              >
                                <label class="theme-value">
                                  {{ "searchComponent.quoteDate" | translate }}
                                  <span class="theme-label">
                                    {{
                                      tt.quote.quote_date
                                        | datePipeFormat
                                        | date : "dd/MM/yyyy"
                                    }}
                                  </span>
                                </label>
                              </ng-container>
                              <ng-container
                                *ngIf="lob_value !== 'personalaccident' && 
                                  (finalcardstatus == 'proposal' ||
                                    finalcardstatus == 'policy' ||
                                    finalcardstatus == 'certificate') &&
                                  ((tt.policy_start_date_dt != '' &&
                                    tt.policy_start_date_dt != undefined &&
                                    tt.policy_start_date_dt != 'null' && tt.policy_start_date_dt != null) ||
                                    (tt.data && tt.data.search &&
                                      tt.data.search.policy_start_date != '' &&
                                      tt.data.search.policy_start_date !=
                                        undefined && tt.data.search.policy_start_date !=
                                        null && tt.data.search.policy_start_date !=
                                        'null'))
                                "
                              >
                                <label class="theme-value">
                                  {{
                                    "searchComponent.policyStartDate"
                                      | translate
                                  }}
                                  <span class="theme-label">
                                    {{
                                      tt.policy_start_date_dt != '' &&
                                      tt.policy_start_date_dt != undefined &&
                                      tt.policy_start_date_dt != 'null' && tt.policy_start_date_dt != null
                                        ? (tt.policy_start_date_dt
                                          | date : "dd/MM/yyyy")
                                        : (tt.data?.search.policy_start_date
                                          | date : "dd/MM/yyyy")
                                    }}
                                  </span>
                                </label>
                              </ng-container>
                              <ng-container
                                *ngIf="lob_value == 'personalaccident' &&
                                  (finalcardstatus == 'proposal' ||
                                    finalcardstatus == 'policy' ||
                                    finalcardstatus == 'certificate') &&
                                  (tt.policy_start_date != '' &&
                                    tt.policy_start_date != undefined &&
                                    tt.policy_start_date != 'null' && tt.policy_start_date != null)"
                              >
                                <label class="theme-value">
                                  {{
                                    "searchComponent.policyStartDate"
                                      | translate
                                  }}
                                  <span class="theme-label">
                                    {{
                                      tt.policy_start_date != '' &&
                                      tt.policy_start_date != undefined &&
                                      tt.policy_start_date != 'null' && tt.policy_start_date != null
                                        ? (tt.policy_start_date
                                          | date : "dd/MM/yyyy")
                                        : '-'
                                    }}
                                  </span>
                                </label>
                              </ng-container>
                            </ng-container>
                          </ng-container>

                          <ng-container *ngIf="!receiptSearchBtn">
                            <ng-container
                              *ngIf="
                                finalcardstatus == 'quote' ||
                                finalcardstatus == 'draft'
                              "
                            >
                              <label
                                class="theme-value"
                                *ngIf="
                                  finalcardstatus == 'quote' ||
                                  finalcardstatus == 'draft'
                                "
                              >
                                {{
                                  "searchComponent.quoteNumber.label"
                                    | translate
                                }}
                                <span class="theme-label">
                                  <ng-container
                                    *ngIf="tt?.data?.renewal_quote_id &&
                                      (finalcardstatus == 'quote' ||
                                        finalcardstatus == 'draft') 
                                    "
                                  >
                                    {{
                                      tt?.data?.renewal_quote_id
                                    }}</ng-container
                                  >
                                  <ng-container
                                    *ngIf="
                                      !this.service.non_rater_product_group_id.includes(
                                        tt.product_group_id
                                      ) && !tt?.data?.renewal_quote_id &&
                                      (finalcardstatus == 'quote' ||
                                        finalcardstatus == 'draft')
                                    "
                                  >
                                    {{
                                      tt.quote_no ? tt.quote_no : tt.quote_id
                                    }}</ng-container
                                  >
                                  <ng-container
                                    *ngIf="
                                      this.service.non_rater_product_group_id.includes(
                                        tt.product_group_id
                                      ) && !tt?.data?.renewal_quote_id &&
                                      (finalcardstatus == 'quote' ||
                                        finalcardstatus == 'draft')
                                    "
                                  >
                                    {{ tt.cust_fld_1 }}</ng-container
                                  >
                                </span>
                              </label>
                            </ng-container>
                          </ng-container>

                          <ng-container *ngIf="!receiptSearchBtn">
                            <label
                              class="theme-value"
                              *ngIf="finalcardstatus == 'proposal'"
                            >
                              {{ "searchComponent.proposalNumber" | translate }}
                              <span
                                class="theme-label"
                                *ngIf="finalcardstatus == 'proposal'"
                              >
                                {{
                                  tt.proposal_no
                                    ? tt.proposal_no
                                    : tt.proposal_id
                                }}
                              </span>
                            </label>
                          </ng-container>

                          <ng-container *ngIf="!receiptSearchBtn">
                            <label
                              class="theme-value"
                              *ngIf="
                                ((finalcardstatus == 'policy' ||
                                  finalcardstatus == 'certificate') &&
                                  tt.cust_fld_1?.length > 0 &&
                                  tt.product_id == service.gagProductId) ||
                                ((finalcardstatus == 'policy' ||
                                  finalcardstatus == 'certificate') &&
                                  tt.product_id != service.gagProductId)
                              "
                            >
                              <ng-container
                                *ngIf="
                                  (finalcardstatus == 'policy' ||
                                    finalcardstatus == 'certificate') &&
                                  tt.cust_fld_1?.length > 0 &&
                                  tt.product_id == service.gagProductId
                                "
                                >{{
                                  "searchComponent.certificateNumber"
                                    | translate
                                }}
                              </ng-container>
                              <ng-container
                                *ngIf="
                                  (finalcardstatus == 'policy' ||
                                    finalcardstatus == 'certificate') &&
                                  tt.product_id != service.gagProductId
                                "
                              >
                                {{ "searchComponent.policyNumber" | translate }}
                              </ng-container>
                              <span
                                class="theme-label"
                                *ngIf="
                                  finalcardstatus == 'policy' ||
                                  finalcardstatus == 'certificate'
                                "
                              >
                                <span
                                  *ngIf="tt.product_id == service.cyberSheild"
                                >
                                  {{
                                    tt.data.certificate_seq_no
                                      ? tt.data.certificate_seq_no
                                      : tt.policy_no
                                      ? tt.policy_no
                                      : tt.policy_id
                                  }}
                                </span>
                                <span
                                  *ngIf="tt.product_id == service.gagProductId"
                                >
                                  {{ tt.cust_fld_1 }}
                                </span>
                                <span
                                  *ngIf="
                                    tt.product_id != service.cyberSheild &&
                                    tt.product_id != service.gagProductId
                                  "
                                >
                                  {{
                                    tt.policy_no ? tt.policy_no : tt.policy_id
                                  }}
                                </span>
                              </span>
                            </label>
                          </ng-container>

                          <ng-container *ngIf="!receiptSearchBtn">
                            <label
                              class="theme-value"
                              *ngIf="
                                tt.data.search != null &&
                                tt.data.search != undefined &&
                                tt.data.search.sub_product != undefined
                              "
                            >
                              {{ "searchComponent.subProduct" | translate }}
                              <span
                                class="theme-label"
                                *ngIf="
                                  tt.data.search != null &&
                                  tt.data.search != undefined &&
                                  tt.data.search.sub_product != undefined
                                "
                              >
                                {{ tt.data?.search.sub_product }}
                              </span>
                            </label>
                          </ng-container>

                          <ng-container *ngIf="receiptSearchBtn">
                            <label
                              class="theme-value"
                              *ngIf="
                                tt?.proposal_no?.length > 0 &&
                                (tt.policy_no === null ||
                                  tt.policy_no == undefined ||
                                  tt.policy_no?.length === 0 ||
                                  tt.policy_no == 'null')
                              "
                            >
                              {{ "searchComponent.proposalNumber" | translate }}
                              <span
                                class="theme-label"
                                *ngIf="
                                  tt?.proposal_no?.length > 0 &&
                                  (tt.policy_no === null ||
                                    tt.policy_no == undefined ||
                                    tt.policy_no?.length === 0 ||
                                    tt.policy_no == 'null')
                                "
                              >
                                {{ tt.proposal_no }}
                              </span>
                            </label>
                          </ng-container>

                          <ng-container *ngIf="receiptSearchBtn">
                            <label
                              class="theme-value"
                              *ngIf="tt?.policy_no?.length > 0"
                            >
                              {{ "searchComponent.policyNumber" | translate }}
                              <span
                                class="theme-label"
                                *ngIf="tt?.policy_no?.length > 0"
                              >
                                {{ tt.policy_no }}
                              </span>
                            </label>
                          </ng-container>

                          <ng-container *ngIf="receiptSearchBtn">
                            <label
                              class="theme-value"
                              *ngIf="
                                tt.data.search != null &&
                                tt.data.search != undefined &&
                                tt.data.search.sub_product != undefined
                              "
                            >
                              {{ "searchComponent.subProduct" | translate }}
                              <span
                                class="theme-label"
                                *ngIf="
                                  tt.data.search != null &&
                                  tt.data.search != undefined &&
                                  tt.data.search.sub_product != undefined
                                "
                              >
                                {{ tt.data?.search.sub_product }}
                              </span>
                            </label>
                          </ng-container>
                          
                          <ng-container *ngIf="tt?.quote?.data?.agent_first_name">
                            <label class="theme-value">Created by <span class="theme-label">{{ tt.quote.data.agent_first_name }}</span></label>
                          </ng-container>
                        </div>
                      </div>
                      <button
                      class="btn link-btn mb-4"
                      *ngIf="
                        lta &&
                        finalcardstatus == 'policy' &&
                        !tt.buttonClick
                      "
                      (click)="
                        ButtonClick(
                          cardlist[i].policy_no,
                          $event,
                          cardlist[i]
                        )
                      "
                    >
                      Check Status
                    </button>

                      
                      <div
                        class="tata-card-footer different-msg"
                        *ngIf="
                          (isTravel &&
                            finalcardstatus == 'policy' &&
                            tt.status == 4) ||
                          (lta &&
                            finalcardstatus == 'policy' &&
                            !tt.buttonClick) ||
                          (!mobNstpStatus &&
                            tt.data.search != null &&
                            tt.data.search != undefined &&
                            tt.data?.search?.nstp_status != '-' &&
                            (finalcardstatus == 'quote' ||
                              finalcardstatus == 'proposal' ||
                              finalcardstatus == 'draft' ||
                              finalcardstatus == 'policy') &&
                            tt.data?.search?.nstp_status != null &&
                            tt.data.search.nstp_status != undefined &&
                            tt.data?.search?.nstp_status != '') ||
                          
                          ((lob_value == 'marinecargo' ||
                            lob_value == 'aigcombined') &&
                            tt.quote?.data?.sub_transaction_type != undefined)
                        "
                      >
                      
                        <ng-container
                          *ngIf="
                            lta && finalcardstatus == 'policy' && tt.buttonClick
                          "
                        >
                          <span
                            class="footer-error text-capitalize text-truncate"
                            *ngIf="tt.buttonClick && tt.iscancelled"
                          >
                            {{ "searchComponent.cancelled.label" | translate }}
                          </span>
                          <span
                            class="footer-info text-capitalize text-truncate"
                            *ngIf="tt.buttonClick && !tt.iscancelled"
                            >{{
                              "searchComponent.policyGenerated.label"
                                | translate
                            }}</span
                          >
                        </ng-container>
                        <ng-container
                          *ngIf="
                            !mobNstpStatus &&
                            tt.data.search != null &&
                            tt.data.search != undefined
                          "
                        >
                          <span
                            class="footer-info text-capitalize text-truncate"
                            *ngIf="
                              (finalcardstatus == 'quote' ||
                                finalcardstatus == 'proposal' ||
                                finalcardstatus == 'draft' ||
                                finalcardstatus == 'policy') &&
                              tt.data.search.nstp_status != null &&
                              tt.data.search.nstp_status != undefined &&
                              tt.data.search.nstp_status != ''
                            "
                          >
                            {{ tt.data?.search.nstp_status }}
                          </span>
                        </ng-container>
                        <ng-container
                          *ngIf="
                            tt.quote?.data?.sub_transaction_type != undefined
                          "
                        >
                          <span
                            class="footer-info text-capitalize text-truncate"
                            *ngIf="
                              finalcardstatus == 'quote' ||
                              finalcardstatus == 'proposal' ||
                              finalcardstatus == 'draft' ||
                              finalcardstatus == 'policy'
                            "
                          >
                            {{ tt.quote?.data?.sub_transaction_type }}
                          </span>
                        </ng-container>
                        <span
                          class="footer-error text-capitalize text-truncate"
                          *ngIf="
                            isTravel &&
                            finalcardstatus == 'policy' &&
                            tt.status == 4
                          "
                        >
                          {{ "searchComponent.cancelled.label" | translate }}
                        </span>
                      </div>
                    </label>
                  </div>
                </div>
              </div>

              <!-- cross sell card start -->
              <div *ngIf="isCrossSellFilter" class="row">
                <div class="col-md-6 col-lg-4 col-xl-3" *ngFor="let tt of this.cardlist; let j = index">
                  <div (click) = "navigation(
                      tt,
                      j,
                      ConfirmationMessage_template,
                      itemsPerPageGrid
                    )" class="tata-card-container">
                    <label class="tata-card" for="tata1">
                      <div class="tata-card-header dropdown-section">
                        <div>
                          <div class="header-title">
                            {{ "searchComponent.crossSell.label" | translate }}
                          </div>
                          <div class="header-sub-title">
                            {{tt.data.search.product_nameString}}
                          </div>
                        </div>            
                      </div>
                      <div class="tata-card-body">
                        <div class="premium-section">
                          <label class="premium-label">Premium Amount</label>
                          <span class="premium-value">{{tt.data.search.premium | inrcurrency }}/-</span>
                        </div>
                        <div class="info-section">
                          <label class="theme-value">Customer Name<span class="theme-label">{{tt?.data?.search?.customer_name}}</span></label>
                          <label class="theme-value">Mobile Number<span class="theme-label">{{tt?.data?.search?.customer_mobileno}}</span></label>
                          <label *ngIf="finalcardstatus == 'proposal'" class="theme-value">Proposal Number<span class="theme-label"></span></label>
                          <label *ngIf="finalcardstatus == 'quote'" class="theme-value">Quote Number<span
                              class="theme-label"></span></label>
                          <label *ngIf="finalcardstatus == 'draft'" class="theme-value">Quote Id<span class="theme-label"></span></label>
                          <label *ngIf="finalcardstatus == 'policy'" class="theme-value">Policy Number<span class="theme-label"></span></label>
                          
                          <ng-container *ngFor="let item of tt.data.search.product_name; let i = index">
                          <label *ngIf="finalcardstatus == 'proposal'" class="theme-value">{{item}}<span class="theme-label">{{tt.data?.search?.proposal_no[i]}} </span> <span class="theme-label"> {{tt.data?.search?.nstp_status[i] ? tt.data?.search?.nstp_status[i] : '' }}</span></label>

                          <label *ngIf="finalcardstatus == 'quote'" class="theme-value">{{item}}<span class="theme-label">{{tt.data?.search?.quote_no[i]}} </span> <span class="theme-label"> {{tt.data?.search?.nstp_status[i] ? tt.data?.search?.nstp_status[i] : '' }} </span></label>
                          <label *ngIf="finalcardstatus == 'draft'" class="theme-value">{{item}}<span class="theme-label">{{tt.data?.search?.quote_id[i]}} </span> <span class="theme-label">{{tt.data?.search?.nstp_status[i] ? tt.data?.search?.nstp_status[i] : '' }}</span></label>
                          <label *ngIf="finalcardstatus == 'policy'" class="theme-value">{{item}}<span class="theme-label">{{tt.data?.search?.quote_id[i]}} </span> <span class="theme-label"> {{tt.data?.search?.nstp_status[i] ? tt.data?.search?.nstp_status[i] : '' }}</span></label>
                          </ng-container>
                        </div>
                      </div>
                      <!-- <div class="tata-card-footer different-msg">
                        <span class="footer-info">Pending</span>
                      </div> -->
                    </label>
                  </div>
                </div>
              </div>
              <!-- cross sell card end -->
            </div>

            <!-- new mat table -->
            <table
              mat-table
              [dataSource]="cardlist"
              class="preview-details w-100 card-top-spacing d-none"
              [ngClass]="{ 'd-md-table': listItem }"
              multiTemplateDataRows
            >
              <div matColumnDef="ProductName">
                <td mat-cell *matCellDef="let element" class="border-none">
                  <div class="table-banner banner-info"  *ngIf="addedToCartCheck(element)">
                    Added in cart
                  </div>
                  <div class="d-flex mt-15"
                    *ngIf="
                      element.data.search != null &&
                      element.data.search != undefined
                    "
                  >
                    <div class="theme-value">
                      {{
                        element.data?.search?.product_name
                          ? element.data?.search?.product_name
                          : "-"
                      }}
                    </div>
                  </div>
                  <div class="d-flex mt-15"
                    *ngIf="
                      !(
                        element.data.search != null &&
                        element.data.search != undefined
                      )
                    "
                  >
                    <div class="theme-value">-</div>
                  </div>
                </td>
              </div>

              <div matColumnDef="CustomerName">
                <td
                  mat-cell
                  *matCellDef="let element"
                  class="brdr-none d-sm-none d-none d-md-table-cell"
                >
                  <div
                    class="theme-label"
                    *ngIf="
                      element.data.search != null &&
                      element.data.search != undefined &&
                      element.data.search.member_count != undefined
                    "
                  >
                    {{ element.data?.search.member_count }}
                    {{ "searchComponent.travelers.label" | translate }}
                  </div>
                  <div
                    class="theme-value"
                    *ngIf="
                      element.data.search != null &&
                      element.data.search != undefined
                    "
                  >
                    {{ element.data?.search.customer_name }}
                  </div>

                  <label
                    class="theme-label"
                    *ngIf="
                      element?.quote?.data?.agent_first_name
                    "
                  >
                    Created by: {{ element?.quote?.data?.agent_first_name }}
                  </label>
                </td>
              </div>

              <div matColumnDef="NewProduct">
                <td
                  mat-cell
                  *matCellDef="let element"
                  class="brdr-none d-md-table-cell d-none d-sm-none"
                >
                  <div
                    *ngIf="
                      element.data.search != null &&
                      element.data.search != undefined &&
                      element.data.search.selected_new_product_name != undefined
                    "
                  >
                    <span
                      class="theme-label"
                      *ngIf="
                        element.data.search.selected_new_product_name !=
                        undefined
                      "
                    >
                      {{ element.data?.search.selected_new_product_name }}</span
                    >
                    <div
                      class="theme-label"
                      *ngIf="
                        element.data.search != null &&
                        element.data.search != undefined &&
                        element.data.search.selected_new_product_name !=
                          undefined
                      "
                    >
                      <span
                        class="premium-value"
                        *ngIf="element.data.search.coverage_period != undefined"
                      >
                        {{ element.data?.search.coverage_period }}
                        {{ "searchComponent.days.label" | translate }}/
                        {{ element.data?.search.selected_zone }}
                      </span>
                    </div>
                  </div>
                </td>
              </div>

              <div matColumnDef="ProducerName">
                <td
                  mat-cell
                  *matCellDef="let element"
                  class="brdr-none d-md-table-cell d-none d-sm-none"
                >
                  <div
                    *ngIf="
                      isUW &&
                      fieldshow.gc_channel &&
                      element.data.search != null &&
                      element.data.search != undefined &&
                      element.data?.search.producer_name
                    "
                  >
                    <span class="theme-label">
                      {{
                        "searchComponent.producerName.label" | translate
                      }}</span
                    >
                    <div
                      class="theme-value"
                      *ngIf="
                        element.data.search != null &&
                        element.data.search != undefined
                      "
                    >
                      {{ element.data?.search.producer_name }}
                    </div>
                  </div>
                </td>
              </div>

              <div matColumnDef="channel">
                <td
                  mat-cell
                  *matCellDef="let element"
                  class="brdr-none d-md-table-cell d-none d-sm-none"
                >
                  <div
                    *ngIf="
                      isUW &&
                      fieldshow.gc_channel &&
                      element.data.search != null &&
                      element.data.search.channel != undefined &&
                      element.data.search.channel
                    "
                  >
                    <div
                      class="quote-search-value"
                      *ngIf="
                        element.data.search != null &&
                        element.data.search.channel != undefined
                      "
                    >
                      {{ element.data?.search.channel }}
                    </div>
                    <div
                      class="quote-search-value"
                      *ngIf="
                        element.data.search != null &&
                        element.data.search != undefined
                      "
                    >
                      {{ element.data?.search.location }}
                    </div>
                  </div>
                </td>
              </div>

              <div matColumnDef="Policynumber">
                <td
                  mat-cell
                  *matCellDef="let element"
                  class="brdr-none d-md-table-cell d-none d-sm-none"
                >
                  <div *ngIf="!receiptSearchBtn">
                    <div class="quote-search-value">
                      <div
                        *ngIf="
                          finalcardstatus == 'quote' ||
                          finalcardstatus == 'draft'
                        "
                      >
                        {{element?.data?.renewal_quote_id ? element?.data?.renewal_quote_id :
                          (element.quote_no ? element.quote_no : element.quote_id)
                        }}
                      </div>
                      <div *ngIf="finalcardstatus == 'proposal'">
                        {{
                          element.proposal_no
                            ? element.proposal_no
                            : element.proposal_id
                        }}
                      </div>
                      <span
                        *ngIf="
                          finalcardstatus == 'policy' ||
                          finalcardstatus == 'certificate'
                        "
                      >
                        <span *ngIf="element.product_id == service.cyberSheild">
                          {{
                            element.data.certificate_seq_no
                              ? element.data.certificate_seq_no
                              : element.policy_no
                              ? element.policy_no
                              : element.policy_id
                          }}
                        </span>
                        <span
                          *ngIf="
                            !(
                              selectedLobValue == 'marinecargo' &&
                              element?.cust_fld_3?.toLowerCase() == 'renewal'
                            ) && element.product_id != service.cyberSheild
                          "
                        >
                          {{
                            element.policy_no
                              ? element.policy_no
                              : element.policy_id
                          }}
                        </span>
                        <span
                          *ngIf="
                            selectedLobValue == 'marinecargo' &&
                            element?.cust_fld_3?.toLowerCase() == 'renewal'
                          "
                        >
                          {{ element.cust_fld_5 }}
                        </span>
                        <span
                          class="head-sm"
                          *ngIf="
                            element.data.search != null &&
                            element.data.search != undefined &&
                            element.data.search.sub_product != undefined
                          "
                          >{{ element.data?.search.sub_product }}</span
                        >
                      </span>
                    </div>
                    <div class="d-flex">
                      <svg
                        class="svg-xs pr-2 svg-yesterday"
                        *ngIf="
                          ((finalcardstatus == 'quote' ||
                            finalcardstatus == 'draft') &&
                            element.quote?.quote_date != undefined) ||
                          ((finalcardstatus == 'proposal' ||
                            finalcardstatus == 'policy' ||
                            finalcardstatus == 'certificate') &&
                            ((element.policy_start_date_dt != '' &&
                              element.policy_start_date_dt != undefined &&
                              element.policy_start_date_dt != 'null') ||
                              (element.data.search &&
                                element.data.search.policy_start_date != '' &&
                                element.data.search.policy_start_date !=
                                  undefined)))
                        "
                      >
                        <use xlink:href="#ICON_Yesterday_small" />
                      </svg>
                      <div
                        class="theme-label quote-search-value"
                        *ngIf="
                          (finalcardstatus == 'quote' ||
                            finalcardstatus == 'draft') &&
                          element.quote?.quote_date != undefined
                        "
                      >
                        {{
                          element.quote.quote_date
                            | datePipeFormat
                            | date : "dd/MM/yyyy"
                        }}
                      </div>
                      <div
                        class="theme-label quote-search-value"
                        *ngIf="lob_value != 'personalaccident' &&
                          (finalcardstatus == 'proposal' ||
                            finalcardstatus == 'policy' ||
                            finalcardstatus == 'certificate') &&
                          ((element.policy_start_date_dt != '' &&
                            element.policy_start_date_dt != undefined &&
                            element.policy_start_date_dt != 'null' && element.policy_start_date_dt != null) ||
                            (element.data.search &&
                              element.data.search.policy_start_date != '' &&
                              element.data.search.policy_start_date !=
                                undefined && element.data.search.policy_start_date !=
                                null &&  element.data.search.policy_start_date !=
                                'null'))
                        "
                      >
                        {{
                        element.policy_start_date_dt != '' &&
                        element.policy_start_date_dt != undefined &&
                        element.policy_start_date_dt != 'null' && element.policy_start_date_dt != null
                            ? (element.policy_start_date_dt
                              | datePipeFormat
                              | date : "dd/MM/yyyy")
                            : element.data?.search.policy_start_date
                        }}
                      </div>
                      <div
                        class="theme-label quote-search-value"
                        *ngIf="lob_value == 'personalaccident' && 
                          (finalcardstatus == 'proposal' ||
                            finalcardstatus == 'policy' ||
                            finalcardstatus == 'certificate') &&
                          ((element.policy_start_date != '' &&
                            element.policy_start_date != undefined &&
                            element.policy_start_date != 'null' && element.policy_start_date != null))
                        "
                      >
                        {{
                        element.policy_start_date != '' &&
                        element.policy_start_date != undefined &&
                        element.policy_start_date != 'null' && element.policy_start_date != null
                            ? (element.policy_start_date
                              | datePipeFormat
                              | date : "dd/MM/yyyy")
                            : '-'
                        }}
                      </div>
                    </div>
                  </div>
                  <div
                    *ngIf="receiptSearchBtn"
                    class="table-data-container px-3 border-dotted-left"
                  >
                    <div class="color-asqi quote-search-value">
                      <span
                        class="head-sm"
                        *ngIf="element?.proposal_no?.length > 0"
                        >{{ element.proposal_no }}</span
                      >
                      <span
                        class="head-sm"
                        *ngIf="element?.policy_no?.length > 0"
                        >{{ element.policy_no }}</span
                      >
                      <span
                        class="head-sm"
                        *ngIf="
                          element.data.search != null &&
                          element.data.search != undefined &&
                          element.data.search.sub_product != undefined
                        "
                        >{{ element.data?.search.sub_product }}</span
                      >
                    </div>
                  </div>
                </td>
              </div>

              <div matColumnDef="Premium">
                <td
                  mat-cell
                  *matCellDef="let element"
                  class="brdr-none d-md-table-cell d-none"
                >
                  <div class="theme-label">
                    {{ "searchComponent.premium.label" | translate }}
                  </div>
                  <div
                    *ngIf="
                      element.data.search != null &&
                      element.data.search != undefined
                    "
                  >
                    <span class="theme-value"
                      ><i class="fa fa-inr"> </i
                      >{{ element.data?.search.premium | inrcurrency }}</span
                    >
                  </div>
                  <div
                    *ngIf="
                      !(
                        element.data.search != null &&
                        element.data.search != undefined
                      )
                    "
                  >
                    -
                  </div>
                </td>
              </div>

              <div matColumnDef="cancelcertificate">
                <td
                  mat-cell
                  *matCellDef="let element"
                  class="brdr-none d-md-table-cell d-none"
                >
                  <div
                    class="table-data-container px-3 border-dotted-left link-btn text-center"
                    *ngIf="
                      lob_value == 'travel' &&
                      finalcardstatus == 'policy' &&
                      (role == 'admin' || 'cops') &&
                      privileage == true &&
                      element.status == 0 &&
                      element.canceled != true
                    "
                  >
                    <div
                      class="color-asqi quote-search-value"
                      (click)="
                        cancelcertificate(cancel_certificate);
                        policyID(element.policy_id)
                      "
                    >
                      {{
                        "searchComponent.cancelCertificate.label" | translate
                      }}
                    </div>
                  </div>
                </td>
              </div>

              <div matColumnDef="status">
                <td
                  mat-cell
                  *matCellDef="let element"
                  class="brdr-none d-md-table-cell d-none"
                >
                  <div
                    *ngIf="
                      element.data.search != null &&
                      element.data.search != undefined &&
                      element.data?.search?.nstp_status != '-'
                    "
                  >
                    <span
                      *ngIf="
                        (finalcardstatus == 'quote' ||
                          finalcardstatus == 'proposal' ||
                          finalcardstatus == 'draft' ||
                          finalcardstatus == 'policy') &&
                        element.data.search.nstp_status != null &&
                        element.data.search.nstp_status != undefined &&
                        element.data.search.nstp_status != ''
                      "
                      class="theme-value blue"
                    >
                      {{ element.data?.search.nstp_status }}
                    </span>
                  </div>
                </td>
              </div>

              <div matColumnDef="quicklinks">
                <th mat-header-cell *matHeaderCellDef class="table-heading">
                  <!-- <span class="theme-label">Action</span> -->
                </th>
                <td mat-cell *matCellDef="let element" class="border-none">
                  <button
                    (click)="onEventStopPropogation($event)"
                    class="btn quotelist-btn m-0 mr-2"
                    id="dropdownMenuButton"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <svg height="16" width="16">
                      <use xlink:href="#ICON_Three_dots" />
                    </svg>
                  </button>
                  <ul
                    class="dropdown-menu dropdown-menu-right qoute-dropdown"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <li
                      *ngIf="element.pdf_date && element.pdf_date != 'null'"
                      (click)="
                        downloadPolicy(element); $event.stopPropagation()
                      "
                    >
                      <a class="dropdown-item cursor-pointer">
                        <svg class="svg-xs">
                          <use xlink:href="#ICON_Download_Drop" />
                        </svg>
                        <span class="pl-2">{{
                          "searchComponent.download.label" | translate
                        }}</span>
                      </a>
                    </li>
                    <!-- <li (click)="cloneData(element)">
                      <a class="dropdown-item" href="#">
                        <svg class="svg-xs">
                          <use xlink:href="#ICON_clone" />
                        </svg>
                        <span class="pl-2">{{
                          "searchComponent.clone.label" | translate
                        }}</span>
                      </a>
                    </li> -->
                    <li
                      (click)="
                        openShareModal(element, data); $event.stopPropagation()
                      "
                    >
                      <a class="dropdown-item">
                        <svg class="svg-xs">
                          <use xlink:href="#ICON_Share" />
                        </svg>
                        <span class="pl-2">{{
                          "searchComponent.share.label" | translate
                        }}</span>
                      </a>
                    </li>
                    <li *ngIf="receiptSearchBtn">
                      <a
                        class="dropdown-item"
                        (click)="delete(element); $event.stopPropagation()"
                      >
                        <svg class="svg-xs">
                          <use xlink:href="#ICON_delete" />
                        </svg>
                        <span class="pl-2">{{
                          "searchComponent.delete.label" | translate
                        }}</span>
                      </a>
                    </li>
                    <!-- If U have two btn Renew and other one then one btn will move into the dropdown -->
                    <li *ngIf="service.dAndOProductId == element.product_id">
                      <a
                        class="dropdown-item"
                        (click)="navigateToProduct(element, $event)"
                      >
                        <svg class="svg-xs">
                          <use xlink:href="#ICON_clone" />
                        </svg>
                        <span class="pl-2">{{
                          "searchComponent.clone.label" | translate
                        }}</span>
                      </a>
                    </li>
                  </ul>
                </td>
              </div>


              <tr
                (click)="
                  navigation(
                    element,
                    i,
                    ConfirmationMessage_template,
                    itemsPerPageList
                  )
                "
                mat-row
                *matRowDef="let element; columns: headerList; let i = index"
                class="border-as-bottom mat-quotetable"
                [class.position-relative]="element.cart_id !==  ''"
              ></tr>
            </table>
            <!-- new mat table ends -->
          </div>
        </div>

        <!-- floating button -->
        <div
          class="position-fixed btn-group dropup new-quote-btn-container"
          *ngIf="displaydropdown && (fianlcorrespondingproduct?.length > 0 || this.lob_value?.toLowerCase() === 'combo')"
        >
          <button
            class="btn primary-btn active newQuote dropbtn"
            type="button"
            id="dropdownMenuLink"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            + Create New Quote
          </button>

          <!-- dropdown menu item -->
          <ul
            class="dropdown-menu mb-5 me-2 dropMinWidth"
            aria-labelledby="dropdownMenuLink"
          >
            <li *ngFor="let product of fianlcorrespondingproduct">
              <a
                class="dropdown-item d-flex align-items-center"
                (click)="productsPage(product.product_id, product.url, product)"
              >
                <img
                  class="me-2 dropImgSize"
                  src="{{ service.commonIconPath }}assets/images/{{
                    product.icon_name
                  }}"
                  alt=""
                />
                <span class="font-14">{{ product.product_name }}</span>
              </a>
            </li>
          </ul>
        </div>
      </form>

      <div
        class="w-100 pagination-margin"
        *ngIf="!searchSpinner && submit && cardlist.length > 0"
      >
        <div class="pull-left head-sm">
          <div class="count-txt">
            Total result(s) found :
            {{
              (cardlist | filter : searchText)?.length
                ? (cardlist | filter : searchText)?.length
                : 0
            }}
          </div>
        </div>
        <div
          class="pull-right pagination-entries"
          [ngClass]="{ 'd-md-block': gridItem, 'd-md-none': !gridItem }"
          *ngIf="(cardlist | filter : searchText)?.length"
        >
          <pagination-controls
            id="pagination1"
            class="my-pagination"
            responsive="true"
            (pageChange)="page = $event"
          >
          </pagination-controls>
        </div>
        <div
          class="pull-right pagination-entries d-none"
          [ngClass]="{ 'd-md-table': listItem }"
          *ngIf="(cardlist | filter : searchText)?.length"
        >
          <pagination-controls
            id="pagination2"
            class="my-pagination"
            responsive="true"
            (pageChange)="page = $event"
          >
          </pagination-controls>
        </div>
      </div>
    </div>
    <div
      class="fw-100 pagination-margin"
      *ngIf="!searchSpinner && submit && cardlist.length === 0"
    >
      <div class="pull-left head-sm label-indent mt-md-35">
        <div class="count-txt m-b-md">
          Total result(s) found :
          {{
            (cardlist | filter : searchText)?.length
              ? (cardlist | filter : searchText)?.length
              : 0
          }}
        </div>
      </div>
      <div
        class="pull-right pagination-entries"
        [ngClass]="{ 'd-md-block': gridItem, 'd-md-table': listItem }"
      >
        <button class="quotelist-btn" 
          *ngIf="pageIndex !== 0"
          (click)="previousPage()" 
        >
          {{previousButton}}</button>
      </div>
      <!-- <a class="float-end" (click)="sortfilter(target)" data-bs-toggle="offcanvas" data-bs-target="#filterSidebar"
				aria-controls="filterSidebar" *ngIf="showFilterOnly">
				<button type="button" class="btn quotelist-btn icon-filter">
					<svg class="svg-xs">
						<use xlink:href="#ICON_Filter" />
					</svg>
				</button>
			</a> -->
    </div>
  </div>
</div>

<!-- Sort Filter -->
<div
  class="offcanvas offcanvas-end filter-offcanvas"
  tabindex="-1"
  id="filterSidebar"
  aria-labelledby="filterSidebar"
>
  <div class="offcanvas-header">
    <h5 class="offcanvas-title" id="filterSidebar">
      {{ "searchComponent.sort&Filter.label" | translate }}
    </h5>
    <button
      type="button"
      id="closeFilter"
      class="btn-close text-reset"
      data-bs-dismiss="offcanvas"
      aria-label="Close"
    ></button>
  </div>
  <div class="offcanvas-body">
    <div class="row">
      <div class="col-12">
        <form class="theme-form" (ngSubmit)="filter()" name="form1" #f="ngForm">
          <div class="row">
            <div class="col mb-20">
              <label class="head-sm">{{
                "searchComponent.sortBy.label" | translate
              }}</label>
              <div class="radio-container flex-column">
                <div
                  class="theme-radio radio-btn mb-10"
                  *ngIf="fieldshow.sort_ascending"
                >
                  <input
                    type="radio"
                    value="ascending"
                    name="sort"
                    [(ngModel)]="modelpop.sort"
                    required
                    #sort="ngModel"
                    id="ascending"
                  />
                  <label class="default-radio-label" for="ascending">
                    {{ "searchComponent.date.label" | translate }}
                    {{ "searchComponent.ascending.label" | translate }}</label
                  >
                </div>
                <div
                  class="theme-radio radio-btn mb-10"
                  *ngIf="fieldshow.sort_descending"
                >
                  <input
                    type="radio"
                    value="descending"
                    id="descending"
                    name="sort"
                    [(ngModel)]="modelpop.sort"
                    required
                    #sort="ngModel"
                  />
                  <label class="default-radio-label" for="descending">
                    {{ "searchComponent.date.label" | translate }}
                    {{ "searchComponent.descending.label" | translate }}</label
                  >
                </div>
                <div
                  class="theme-radio radio-btn mb-10"
                  *ngIf="fieldshow.sort_min"
                >
                  <input
                    type="radio"
                    value="min"
                    id="min"
                    name="sort1"
                    [(ngModel)]="modelpop.sort"
                    required
                    #sort="ngModel"
                  />
                  <label class="default-radio-label" for="min">
                    {{ "searchComponent.price.label" | translate }}
                    {{ "searchComponent.lowHigh.label" | translate }}</label
                  >
                </div>
                <div
                  class="theme-radio radio-btn mb-10"
                  *ngIf="fieldshow.sort_max"
                >
                  <input
                    type="radio"
                    value="max"
                    id="max"
                    name="sort1"
                    [(ngModel)]="modelpop.sort"
                    required
                    #sort="ngModel"
                  />
                  <label class="default-radio-label" for="max">
                    {{ "searchComponent.price.label" | translate }}
                    {{ "searchComponent.highLow.label" | translate }}</label
                  >
                </div>
              </div>
            </div>
          </div>

          <div class="row" *ngIf="fieldshow.product_id">
            <div class="col mb-20">
              <div class="head-sm">
                <span>{{
                  "searchComponent.productCategory.label" | translate
                }}</span>
                <span *ngIf="displayproductName?.length > 0"
                  >({{ displayproductName?.length }})</span
                >
                <span
                  *ngIf="displayproductName?.length > 0"
                  class="info-icon premium-tooltip premium-info-icon mr-1"
                >
                  <span class="tooltiptext">
                    <span *ngFor="let item of displayproductName">
                      {{ item }},&nbsp;
                    </span>
                  </span>
                  <svg>
                    <use xlink:href="#ICON_Info" />
                  </svg>
                </span>
              </div>

              <!-- multiselect product category -->
              <ng-multiselect-dropdown
                class="dd-multi-select ngSelectOption 1"
                [placeholder]="'Select'"
                [settings]="productdropdownSettings2"
                [ngModelOptions]="{ standalone: true }"
                ngDefaultControl
                [data]="fianlcorrespondingproduct"
                [(ngModel)]="modelpop.product_id"
                #product_id="ngModel"
                id="product_id"
                (onDeSelect)="onItemDeSelect($event)"
                (onSelect)="selectedProduct($event)"
              >
              </ng-multiselect-dropdown>
              <!-- <div class="radio-container flex-column">
								<div class="theme-radio radio-btn mb-10"
									*ngFor="let tt of fianlcorrespondingproduct">
									<input type="radio" id="{{ tt.product_id }}" value="{{ tt.product_id }}"
										name="product_id" [(ngModel)]="modelpop.product_id" #product_id="ngModel"
										(change)="selectedProduct(tt)" />
									<label class="default-radio-label" for="{{ tt.product_id }}">{{ tt.product_name
										}}</label>
								</div>
							</div> -->
            </div>
          </div>

          <div class="row" *ngIf="fieldshow.quotestatus">
            <div class="col form-field">
              <label class="head-sm">{{
                "searchComponent.status.label" | translate
              }}</label>
              <ng-multiselect-dropdown
                class="dd-multi-select ngSelectOption 1"
                [placeholder]="'Select'"
                [settings]="dropdownSettings1"
                [ngModelOptions]="{ standalone: true }"
                ngDefaultControl
                [data]="dropdownList"
                [(ngModel)]="modelpop.quotestatus"
                #quotestatus="ngModel"
                id="quotestatus"
              >
              </ng-multiselect-dropdown>
            </div>
          </div>

          <!-- business type multiselect -->
          <div
            class="row"
            *ngIf="
              fieldshow.health_business_type || fieldshow.motor_business_type
            "
          >
            <div class="col mb-20">
              <div class="head-sm">
                <span>{{
                  "searchComponent.businessType.label" | translate
                }}</span>
                <span *ngIf="businessTypeName?.length > 0"
                  >({{ businessTypeName?.length }})</span
                >
                <span
                  *ngIf="businessTypeName?.length > 0"
                  class="info-icon premium-tooltip premium-info-icon mr-1"
                >
                  <span class="tooltiptext">
                    <span *ngFor="let item of businessTypeName">
                      {{ item }},&nbsp;
                    </span>
                  </span>
                  <svg>
                    <use xlink:href="#ICON_Info" />
                  </svg>
                </span>
              </div>
              <ng-multiselect-dropdown
                class="dd-multi-select ngSelectOption 1"
                [placeholder]="'Select'"
                [settings]="businesstypedropdownSettings"
                [ngModelOptions]="{ standalone: true }"
                ngDefaultControl
                [data]="businesstypelist"
                [(ngModel)]="modelpop.business_type"
                #business_type="ngModel"
                id="business_type"
                (onSelect)="selectedBusinessProduct($event)"
                (onDeSelect)="onItemDeSelect($event)"
              >
              </ng-multiselect-dropdown>
              <!-- <div class="radio-container flex-column">
								<div class="theme-radio radio-btn mb-10" *ngFor="let tt of businesstypelist">
									<input type="radio" id="{{ tt.id }}" value="{{ tt.value }}" name="business_type"
										[(ngModel)]="modelpop.business_type" #business_type="ngModel"
										(change)="selectedProduct(tt)" />
									<label class="default-radio-label" for="{{ tt.id }}">{{ tt.value }}</label>
								</div>
							</div> -->
            </div>
          </div>

          <div class="row" *ngIf="fieldshow.motor_customer_type">
            <div class="col mb-20">
              <label class="head-sm">{{
                "searchComponent.customerType.label" | translate
              }}</label>
              <div class="radio-container flex-column">
                <div
                  class="theme-radio radio-btn mb-10"
                  *ngFor="let tt of customertypelist; let i = index"
                >
                  <input
                    type="radio"
                    id="{{ tt.id }}"
                    value="{{ tt.value }}"
                    name="customer_type"
                    [(ngModel)]="modelpop.customer_type"
                    #customer_type="ngModel"
                  />
                  <label class="default-radio-label" for="{{ tt.id }}">{{
                    tt.value
                  }}</label>
                </div>
              </div>
            </div>
          </div>

          <div class="row" *ngIf="fieldshow.producer && isUW">
            <div class="col form-field">
              <label class="head-sm">{{
                "searchComponent.producerCode.label" | translate
              }}</label>
              <ng-multiselect-dropdown
                class="dd-multi-select ngSelectOption 1"
                [placeholder]="'Select'"
                [settings]="dropdownSettings1"
                [ngModelOptions]="{ standalone: true }"
                ngDefaultControl
                [data]="producerCodeList"
                [(ngModel)]="modelpop.producercode"
                #producercode="ngModel"
                id="producercode"
              >
              </ng-multiselect-dropdown>
            </div>
          </div>

          <div class="row" *ngIf="fieldshow.gc_channel">
            <div class="col form-field">
              <label class="head-sm">{{
                "searchComponent.gcChannel.label" | translate
              }}</label>
              <ng-multiselect-dropdown
                class="dd-multi-select ngSelectOption 1"
                [placeholder]="'Select'"
                [settings]="dropdownSettings2"
                [ngModelOptions]="{ standalone: true }"
                ngDefaultControl
                [data]="gcchanneltypelist"
                [(ngModel)]="modelpop.gcchannel"
                #gcchannel="ngModel"
                id="gcchannel"
              >
              </ng-multiselect-dropdown>
            </div>
          </div>

          <div class="row" *ngIf="fieldshow.gc_channel && fieldshow.location">
            <div class="col form-field">
              <label class="head-sm">GC Location</label>
              <ng-multiselect-dropdown
                class="dd-multi-select ngSelectOption 1"
                [placeholder]="'Select'"
                [settings]="dropdownSettings3"
                [ngModelOptions]="{ standalone: true }"
                ngDefaultControl
                [data]="gclocationlist"
                [(ngModel)]="modelpop.gclocation"
                #location="ngModel"
                id="gclocation"
              >
              </ng-multiselect-dropdown>
            </div>
          </div>

          <div class="row" *ngIf="fieldshow.location && !fieldshow.gc_channel">
            <div class="col form-field">
              <div class="head-sm">
                {{ "searchComponent.policyLocation.label" | translate }}
              </div>
              <ng-select
                [(ngModel)]="modelpop.location"
                name="location"
                required
                placeholder="{{
                  'searchComponent.select.placeholder' | translate
                }}"
                #location="ngModel"
              >
                <ng-option value="{{ tt }}" *ngFor="let tt of locationlist">{{
                  tt
                }}</ng-option>
              </ng-select>
            </div>
          </div>
          <!-- Marine Non Rater only -->
          <div
            class="row"
            *ngIf="
              (lob_value == 'marinecargo' || lob_value == 'aigcombined') &&
              showSubTransactionType
            "
          >
            <div class="col form-field">
              <div class="head-sm">
                {{ "searchComponent.subTransactionType.label" | translate }}
              </div>
              <ng-select
                *ngIf="showSubTransactionType"
                [(ngModel)]="modelpop.subTransactionType"
                name="subTransactionType"
                required
                placeholder="{{
                  'searchComponent.subTransactionType.placeholder' | translate
                }}"
                #subTransactionType="ngModel"
              >
                <ng-option
                  value="{{ tt.sub_catg_type }}"
                  *ngFor="let tt of subTransactionTypes"
                  >{{ tt.sub_catg_type }}
                </ng-option>
              </ng-select>
            </div>
          </div>

          <div class="row" *ngIf="fieldshow.health_varient">
            <div class="col form-field">
              <div class="head-sm">
                {{ "searchComponent.varient.label" | translate }}
              </div>
              <ng-select
                [(ngModel)]="modelpop.varient"
                name="varient"
                placeholder="{{
                  'searchComponent.select.placeholder' | translate
                }}"
                #varient="ngModel"
                [disabled]="disableVarient"
              >
                <ng-option value="{{ tt.id }}" *ngFor="let tt of varientlist"
                  >{{ tt.value }}
                </ng-option>
              </ng-select>
            </div>
          </div>

          <div class="row" *ngIf="fieldshow.health_tenure">
            <div class="form-field col">
              <div class="head-sm">
                {{ "searchComponent.tenure.label" | translate }}
              </div>
              <ng-select
                [(ngModel)]="modelpop.tenure"
                name="tenure"
                placeholder="{{
                  'searchComponent.select.placeholder' | translate
                }}"
                #tenure="ngModel"
              >
                <ng-option value="{{ tt.id }}" *ngFor="let tt of tenurelist"
                  >{{ tt.value }}
                </ng-option>
              </ng-select>
            </div>
          </div>

          <div class="row" *ngIf="fieldshow.health_plan_type">
            <div class="form-field col">
              <div class="head-sm">
                {{ "searchComponent.planType.label" | translate }}
              </div>
              <ng-select
                [(ngModel)]="modelpop.plan_type"
                name="plan_type"
                placeholder="{{
                  'searchComponent.select.placeholder' | translate
                }}"
                #plan_type="ngModel"
              >
                <ng-option value="{{ tt.id }}" *ngFor="let tt of plantypelist"
                  >{{ tt.value }}
                </ng-option>
              </ng-select>
            </div>
          </div>

          <div class="row" *ngIf="fieldshow.health_member_is_employee">
            <div class="col mb-20">
              <label class="head-sm">{{
                "searchComponent.memberIsEmployee.label" | translate
              }}</label>
              <div class="radio-container flex-column">
                <div
                  class="theme-radio radio-btn mb-10"
                  *ngFor="let tt of memberisemployeelist; let i = index"
                >
                  <input
                    type="radio"
                    id="{{ tt.id }}"
                    value="{{ tt.value }}"
                    name="member_is_employee"
                    [(ngModel)]="modelpop.member_is_employee"
                    #member_is_employee="ngModel"
                    (change)="selectedProduct(tt)"
                  />
                  <label class="default-radio-label" for="{{ tt.id }}">{{
                    tt.value
                  }}</label>
                </div>
              </div>
            </div>
          </div>

          <div class="row" *ngIf="fieldshow.pup_nonpup">
            <div class="col mb-20">
              <label class="head-sm">{{
                "searchComponent.pupNonPup.label" | translate
              }}</label>
              <div class="radio-container flex-column">
                <div class="theme-radio radio-btn mb-10">
                  <input
                    type="radio"
                    id="non_pup"
                    value="non_pup"
                    name="pup_nonpup"
                  />
                  <label class="default-radio-label" for="non_pup">{{
                    "searchComponent.nonPup.label" | translate
                  }}</label>
                </div>

                <div class="theme-radio radio-btn mb-10">
                  <input
                    type="radio"
                    id="pup_direct"
                    value="pup_direct"
                    name="pup_nonpup"
                  />
                  <label class="default-radio-label" for="pup_direct">{{
                    "searchComponent.pup_direct.label" | translate
                  }}</label>
                </div>
              </div>
            </div>
          </div>

          <div class="row" *ngIf="fieldshow.addons">
            <div class="col mb-20">
              <label class="head-sm">{{
                "searchComponent.addons.label" | translate
              }}</label>
              <div class="radio-container flex-column">
                <div class="theme-radio radio-btn mb-10">
                  <input
                    type="radio"
                    id="with_fire"
                    value="with_fire"
                    name="addons"
                  />
                  <label class="default-radio-label" for="with_fire">{{
                    "searchComponent.withFireAddress.label" | translate
                  }}</label>
                </div>

                <div class="theme-radio radio-btn mb-10">
                  <input
                    type="radio"
                    id="without_fire"
                    value="without_fire"
                    name="addons"
                  />
                  <label class="default-radio-label" for="without_fire">{{
                    "searchComponent.withoutFireAddress.label" | translate
                  }}</label>
                </div>
              </div>
            </div>
          </div>

          <div class="row" *ngIf="fieldshow.business_intrup">
            <div class="col mb-20">
              <label class="head-sm">{{
                "searchComponent.addons.label" | translate
              }}</label>
              <div class="radio-container flex-column">
                <div class="theme-radio radio-btn mb-10">
                  <input
                    type="radio"
                    id="with_intru"
                    value="with_intru"
                    name="business_intrup"
                  />
                  <label class="default-radio-label" for="with_intru">{{
                    "searchComponent.withInterruption.label" | translate
                  }}</label>
                </div>

                <div class="theme-radio radio-btn mb-10">
                  <input
                    type="radio"
                    id="without_intru"
                    value="without_intru"
                    name="business_intrup"
                  />
                  <label class="default-radio-label" for="without_intru">{{
                    "searchComponent.withoutInterruption.label" | translate
                  }}</label>
                </div>
              </div>
            </div>
          </div>

          <div class="row" *ngIf="fieldshow.bta_plan_type">
            <div class="form-field col">
              <div class="head-sm" required>
                {{ "searchComponent.planType.label" | translate }}
              </div>
              <ng-select
                [(ngModel)]="modelpop.bta_plan_type"
                name="bta_plan_type"
                placeholder="{{
                  'searchComponent.select.placeholder' | translate
                }}"
                required
                #bta_plan_type="ngModel"
              >
                <ng-option value="{{ tt.id }}" *ngFor="let tt of plantypelist"
                  >{{ tt.value }}
                </ng-option>
              </ng-select>
            </div>
          </div>

          <!--motor-->
          <div class="row" *ngIf="fieldshow.motor_vehicle_status">
            <div class="form-field col">
              <div class="head-sm">
                {{ "searchComponent.vehicleStatus.label" | translate }}
              </div>
              <ng-select
                [(ngModel)]="modelpop.motor_vehicle_status"
                name="motor_vehicle_status"
                placeholder="Vehicle Status"
                #motor_vehicle_status="ngModel"
              >
                <ng-option
                  value="{{ tt.id }}"
                  *ngFor="let tt of vehiclestatuslist"
                >
                  {{ tt.value }}</ng-option
                >
              </ng-select>
            </div>
          </div>

          <div class="row" *ngIf="fieldshow.motor_bundle_type">
            <div class="form-field col">
              <div class="head-sm">
                {{ "searchComponent.bundleType.label" | translate }}
              </div>
              <ng-select
                [(ngModel)]="modelpop.motor_bundle_type"
                name="motor_bundle_type"
                placeholder="{{
                  'searchComponent.selectBundleType.placeholder' | translate
                }}"
                #motor_bundle_type="ngModel"
              >
                <ng-option value="{{ tt.id }}" *ngFor="let tt of bundletypelist"
                  >{{ tt.value }}
                </ng-option>
              </ng-select>
            </div>
          </div>

          <div class="row" *ngIf="fieldshow.ins_dec_from">
            <div class="form-field col">
              <div class="head-sm">
                {{
                  "searchComponent.insuredDeclaredValueFrom.label" | translate
                }}
              </div>
              <input
                type="text"
                [(ngModel)]="modelpop.ins_dec_from"
                name="ins_dec_from"
                placeholder="{{
                  'searchComponent.min.placeholder' | translate
                }}"
                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')"
                #ins_dec_from="ngModel"
                class="form-control"
              />
            </div>
          </div>

          <div class="row" *ngIf="fieldshow.ins_dec_to">
            <div class="form-field col">
              <div class="head-sm">
                {{ "searchComponent.insuredDeclaredValueTo.label" | translate }}
              </div>
              <input
                type="text"
                [(ngModel)]="modelpop.ins_dec_to"
                name="ins_dec_to"
                placeholder="{{
                  'searchComponent.max.placeholder' | translate
                }}"
                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')"
                #ins_dec_to="ngModel"
                class="form-control"
              />
            </div>
          </div>

          <div class="row" *ngIf="fieldshow.sum_insured_from">
            <div class="form-field">
              <div class="head-sm">
                {{ "searchComponent.sumInsuredFrom.label" | translate }}
              </div>
              <input
                type="text"
                [(ngModel)]="modelpop.sum_insured_from"
                name="sum_insured_from"
                placeholder="{{
                  'searchComponent.min.placeholder' | translate
                }}"
                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')"
                #sum_insured_from="ngModel"
                class="form-control"
              />
            </div>
          </div>

          <div class="row" *ngIf="fieldshow.sum_insured_to">
            <div class="form-field">
              <div class="head-sm">
                {{ "searchComponent.sumInsuredTo.label" | translate }}
              </div>
              <input
                type="text"
                [(ngModel)]="modelpop.sum_insured_to"
                name="sum_insured_to"
                placeholder="{{
                  'searchComponent.max.placeholder' | translate
                }}"
                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')"
                #sum_insured_to="ngModel"
                class="form-control"
              />
            </div>
          </div>

          <div class="col" *ngIf="fieldshow.netpremium">
            <div class="form-field">
              <div class="head-sm">
                {{ "searchComponent.netPremium.label" | translate }}
              </div>
              <input
                type="text"
                [(ngModel)]="modelpop.netpremium"
                name="netpremium"
                placeholder="{{
                  'searchComponent.netPremium.label' | translate
                }}"
                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')"
                #netpremium="ngModel"
                class="form-control"
              />
            </div>
          </div>

          <div class="col" *ngIf="fieldshow.lta_bundle_type">
            <div class="form-field">
              <div class="head-sm">
                {{ "searchComponent.bundleType.label" | translate }}
              </div>
              <ng-select
                [(ngModel)]="modelpop.lta_bundle_type"
                name="lta_bundle_type"
                placeholder="{{
                  'searchComponent.select.placeholder' | translate
                }}"
                #lta_bundle_type="ngModel"
              >
                <ng-option value="{{ tt.id }}" *ngFor="let tt of bundletypelist"
                  >{{ tt.value }}
                </ng-option>
              </ng-select>
            </div>
          </div>

          <div class="row" *ngIf="fieldshow.lta_plan_type">
            <div class="col form-field">
              <div class="head-sm">
                {{ "searchComponent.planType.label" | translate }}
              </div>
              <ng-select
                [(ngModel)]="modelpop.lta_plan_type"
                name="lta_plan_type"
                required
                placeholder="{{
                  'searchComponent.select.placeholder' | translate
                }}"
                #lta_plan_type="ngModel"
              >
                <ng-option value="{{ tt.id }}" *ngFor="let tt of plantypelist"
                  >{{ tt.value }}
                </ng-option>
              </ng-select>
            </div>
          </div>

          <div class="row" *ngIf="fieldshow.age">
            <div class="col form-field">
              <div class="head-sm">
                {{ "searchComponent.age.label" | translate }}
              </div>
              <ng-select>
                <ng-option value="daughter">{{
                  "searchComponent.daughter.label" | translate
                }}</ng-option>
                <ng-option value="son">{{
                  "searchComponent.son.label" | translate
                }}</ng-option>
              </ng-select>
            </div>
          </div>

          <div class="row" *ngIf="fieldshow.tensure">
            <div class="col form-field">
              <div class="head-sm">
                {{ "searchComponent.tenure.label" | translate }}
              </div>
              <ng-select>
                <ng-option value="pendingrenewal">{{
                  "searchComponent.pendingRenewal.label" | translate
                }}</ng-option>
                <ng-option value="inprogress">{{
                  "searchComponent.inprogress.label" | translate
                }}</ng-option>
              </ng-select>
            </div>
          </div>
          <div class="row" *ngIf="fieldshow['SME/NON-SME'] && isUW">
            <div class="col mb-20">
              <label class="head-sm">{{
                "searchComponent.smeStatus.label" | translate
              }}</label>
              <div class="radio-container flex-column">
                <div class="theme-radio radio-btn mb-10">
                  <input
                    type="radio"
                    value="SME"
                    name="smestatus"
                    [(ngModel)]="modelpop.smestatus"
                    required
                    #smestatus="ngModel"
                    id="sme"
                  />
                  <label class="default-radio-label" for="sme">
                    {{ "searchComponent.sme.label" | translate }}
                  </label>
                </div>
                <div class="theme-radio radio-btn mb-10">
                  <input
                    type="radio"
                    value="Non-SME"
                    id="nonsme"
                    name="smestatus"
                    [(ngModel)]="modelpop.smestatus"
                    required
                    #smestatus="ngModel"
                  />
                  <label class="default-radio-label" for="nonsme">
                    {{ "searchComponent.nonsme.label" | translate }}
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <label class="head-sm">Created By</label>
              <ng-select
                [(ngModel)]="modelpop.doeFilter"
                name="doeFilter"
                placeholder="{{
                  'searchComponent.select.placeholder' | translate
                }}"
                #doeFilter="ngModel"
                dropdownPosition="top"
              >
                <ng-option value="{{ tt.value }}" *ngFor="let tt of doeArr"
                  >{{ tt.value }}
                </ng-option>
              </ng-select>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="offcanvas-footer">
    <div class="d-flex">
      <button
        type="submit"
        class="btn primary-btn theme-sm-btn active"
        (click)="filter()"
      >
        {{ "searchComponent.applyFilters.label" | translate }}
      </button>
      <button
        type="button"
        (click)="clear(); closeFilter()"
        class="btn primary-btn sm theme-sm-btn"
      >
        {{ "searchComponent.clear.label" | translate }}
      </button>
    </div>
  </div>
</div>

<ng-template #ConfirmationMessage_template>
  <div class="modal-container">
    <div class="detail-container-modal modal-md">
      <div class="modal-header">
        <div class="close-icon" (click)="modalRef.hide()">
          <svg>
            <use xlink:href="#ICON_Cross" />
          </svg>
        </div>
      </div>
      <div class="modal-body succes-container">
        <div class="text-center">
          <div class="head-md text-center">
            {{ "searchComponent.areYouSure.label" | translate }}
          </div>
        </div>
        <div class="text-center p-4 double-btn">
          <button type="button" class="btn primary-btn m" (click)="take_over()">
            {{ "searchComponent.yes.label" | translate }}
          </button>
          <button
            type="button"
            class="btn primary-btn m"
            (click)="modalRef.hide()"
          >
            {{ "searchComponent.no.label" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #cancel_certificate>
  <div class="modal-container">
    <div class="detail-container-modal">
      <div class="modal-header">
        <div class="close-icon" (click)="modalRef.hide()">
          <svg>
            <use xlink:href="#ICON_Cross" />
          </svg>
        </div>
      </div>
      <div class="modal-body succes-container">
        <div class="text-center m-b-md">
          <div>
            <svg class="icon-blue">
              <use xlink:href="#ICON_Sent_successfully" />
            </svg>
          </div>
          <div class="head-md text-center mt-3">
            {{ "searchComponent.wantToCancelCertificate.label" | translate }}
          </div>
        </div>
        <div class="text-center p-8 double-btn filter-btn">
          <button
            type="button"
            class="btn primary-btn sm"
            (click)="cancel_policy()"
          >
            {{ "searchComponent.yes.label" | translate }}
          </button>
          <button
            type="button"
            class="btn primary-btn sm"
            (click)="modalRef.hide()"
          >
            {{ "searchComponent.no.label" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #success_template>
  <div class="detail-container-modal modal-md success-info">
    <div class="modal-header">
      <div class="close-icon" (click)="successModalRef.hide()">
        <svg>
          <use xlink:href="#ICON_Cross" />
        </svg>
      </div>
    </div>
    <div class="modal-body succes-container">
      <div class="row">
        <div class="text-center col-md-12">
          <div>
            <svg class="icon-blue">
              <use xlink:href="#ICON_Sent_successfully" />
            </svg>
          </div>
          <div class="head-md">
            {{
              "searchComponent.quotationGeneratedSuccessfully.label" | translate
            }}
          </div>
        </div>
        <div class="col-md-12 d-flex justify-content-center">
          <table>
            <tr>
              <td class="theme-label">
                {{ "searchComponent.quoteNumber.label" | translate }}
              </td>
              <td class="pl-5 theme-value">{{ quoteRes?.quote_id }}</td>
            </tr>
            <tr>
              <td class="theme-label">
                {{ "searchComponent.name.label" | translate }}
              </td>
              <td class="pl-5 theme-value">
                {{ quoteRes?.data?.search?.customer_name }}
                {{ quoteRes?.data?.search?.lname_proposer }}
              </td>
            </tr>
            <tr>
              <td class="theme-label">
                {{ "searchComponent.mobileNumber.label" | translate }}
              </td>
              <td class="pl-5 theme-value">
                {{ quoteRes?.data?.search?.customer_mobileno }}
              </td>
            </tr>
          </table>
        </div>
        <div class="col-md-12 text-center m-30-0">
          <div class="row">
            <div class="col-6">
              <button
                type="button"
                class="btn primary-btn btn-successModal m-0"
              >
                {{ "searchComponent.dashboard.label" | translate }}
              </button>
            </div>
            <div class="col-6">
              <button
                type="button"
                class="btn primary-btn btn-successModal m-0"
                (click)="continue()"
              >
                {{ "searchComponent.continue.label" | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #customer_email>
  <div class="modal-container">
    <div
      class="detail-container-modal modal-md modal-form contact-detail-modal"
    >
      <div class="modal-header pl-30">
        <h4 class="modal-title pull-left">
          {{ "searchComponent.shareQuote.label" | translate }}
        </h4>
        <div class="close-icon" (click)="shareModelRef.hide()">
          <svg>
            <use xlink:href="#ICON_Cross" />
          </svg>
        </div>
      </div>
      <div class="modal-body has-form">
        <form [formGroup]="contactDetailForm" class="theme-form">
          <div class="row">
            <div class="form-field col-md-12">
              <div for="usr" class="head-sm required-field">
                {{ "searchComponent.customerEmail.label" | translate }}
              </div>
              <input
                type="text"
                formControlName="customerEmail"
                placeholder="{{
                  'searchComponent.customerEmail.label' | translate
                }}"
                class="form-control text-transform-none"
                id="customerEmail"
              />
              <span
                *ngIf="
                  contactDetailForm.get('customerEmail').hasError('required')
                "
                class="error-msg"
                >{{
                  "searchComponent.customerEmail.required" | translate
                }}</span
              >
              <span *ngIf="invalidMsg" class="error-msg">{{
                "searchComponent.invalidEmail.required" | translate
              }}</span>
            </div>
            <div class="col-md-12 text-center m-t-xs">
              <div class="row d-flex justify-content-center">
                <div class="col-6 col-md-6">
                  <button
                    type="button"
                    (click)="emailSend()"
                    [class.active]="!contactDetailForm.invalid"
                    [disabled]="contactDetailForm.invalid"
                    class="btn primary-btn m-0"
                  >
                    {{ "searchComponent.send" | translate }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-template>
