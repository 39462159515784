import { NgModule } from "@angular/core";
import { DatePipeFormat } from "./DatePipeFormat.pipe";
import { InrcurrencyPipe } from "./inrcurrency.pipe";

@NgModule({
  declarations: [DatePipeFormat, InrcurrencyPipe],
  imports: [],
  exports: [DatePipeFormat, InrcurrencyPipe],
})
export class SharedModule {}
